import React from "react";
import PrismCode from '../PrismCode'

//import {Link} from "react-router-dom";

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
from krita import *

print(Krita.instance().filters())
`;


const codeBlockTwo = `
from krita import *

application = Krita.instance()
currentDoc = application.activeDocument()
currentLayer = currentDoc.activeNode()

# grab the filter to work with
sharpenFilter = application.filter('sharpen')

# apply( layer, x, y, width, height  )
sharpenFilter.apply(currentLayer, 0, 0, 1280, 720)
currentDoc.refreshProjection()  # update UI
`;




const codeBlockThree = `
from krita import *

application = Krita.instance()
currentDoc = application.activeDocument()
currentLayer = currentDoc.activeNode()

noiseFilter = application.filter('noise')
noiseFilterConfig = noiseFilter.configuration()

# show available properties you can modify
print( noiseFilterConfig.properties() )

# update a couple of the properties
noiseFilterConfig.setProperty('opacity', 255)
noiseFilterConfig.setProperty('level', 13)
noiseFilter.setConfiguration(noiseFilterConfig)

#see existing config property
print( noiseFilterConfig.property('level') )

# apply filter to layer
noiseFilter.apply(currentLayer, 0, 0, currentDoc.width(), currentDoc.height())
currentDoc.refreshProjection() 
`;






class LessonFilters extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

        <h3>Available filters</h3>
        
        <p>Krita has a lot of filters between the built-in ones, and the extra ones provided by the G'Mic library. With the filters API, you only have the internal filters available. To get a list of all the filters you can use, you can print them out from Python with this code.</p>

        <blockquote>
          <p><AiOutlineBulb /> Note</p>
          <p>The filters API is still a work in progress. While it has some functionality, it is a bit limited and rough around the edges.</p>
        </blockquote>

        <PrismCode code={codeBlockOne} language="python" />

        <p>The output will contain an array of strings that contain the IDs for all the filters that can be applied. Let's see how to apply one next.</p>

        <h3>Apply a filter</h3>

        <p>We have a list of IDs of filters we can apply. Let's apply the "sharpen" filter from the list. This is how we would apply it to a layer. </p>

        <PrismCode code={codeBlockTwo} language="python" />

        <h3>Manually configuring filters</h3>

        <p>While some filters don't require any settings, most do. Each filter has its own configuration object. This configuration object can be updated to change the results of the filter. Let's see how to grab the configuration object and update a few properties before applying it.</p>

        <PrismCode code={codeBlockThree} language="python" />

        <p>Grabbing this configuration will work sometimes depending on the filter. There are currently still some bugs though. For example, if you try to grab the configuration option for the 'halftone' filter, Scripter will give an error that it cannot convert a KoColor to python. As scripting mature in Krita, this issue will be fixed. This page will be updated whenever fixes or changes happen with the filter API.</p>    

      </div>
    );
  }

}

export default LessonFilters;



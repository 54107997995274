import React from "react";
// import {Link} from "react-router-dom";

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

class LessonPluginsIntro extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

    <h2>Why use plugins</h2>

    <p>Plugins do similar things that normal scripts do, so why would we need them? Plugins are more convenient for artists to use and have a number of advantages.</p>

    <h4>Pros of plugins</h4>

    <ol>
        <li>Load automatically when Krita launches</li>
        <li>Portable and easy for non-technical people to use</li>
        <li>Provide additional functionality that is harder to do with normal scripting</li>
        <li>Easier to manage larger coding projects that use multiple files</li>
    </ol>

    <p>If they have so many advantages, why don't we always use plugins? These are some of the disadvantages of using plugins</p>


    <h4>Cons of plugins</h4>

    <ol>
        <li>Slower to develop. Krita needs to restart to test any changes</li>
        <li>More files and code for doing simple things</li>
        <li>More places where things can go wrong in the code</li>
    </ol>

    <p>All of the cons relate to being a developer. For an artist, plugins are a 100% a better option in the end. This is also why plugins are being introduced after most of the core concepts have been discussed. Understanding the core concepts are going to make learning about and using plugins easier.</p>

    <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>When starting a plugin, you can make progress much faster if you use Scripter as long as possible. Being able to instantly see results without having to restart Krita can save hours of time.</p>
    </blockquote>


    <h3>Types of plugins</h3>

    <p>Knowing plugins are great and easy for people to use, I am sure you want to be using them. When you create a plugin there are two types of plugins that can be created.</p>

    <ul>
        <li><em>Extensions</em> - A menu item will be added to the scripts main menu. The script can do anything you want.  All the main menu items in Krita from <strong>Tools &gt; Scripts </strong> are extensions.</li>
        <li><em>Dockers</em> - Special windows that you can arrange, lock, or float, with other dockers. Many of the dockers in Krita are plugins already such as the Quick settings docker and the last documents docker.</li>
    </ul>

    <p>Picking which one to choose depends on what type of functionality you want to have. If there is a large user interface like Scripter, extensions are a better bet. If it is a new color selector or toolbox you always want on the screen, dockers would be better.</p>


    <h3>Managing plugins</h3>

    <p>Plugins are managed through the configuration area in Krita under the Python Plugin Manager. From the main menu go to <strong> Settings &gt; Configure Krita </strong>. From there there will be a python plugin manager area on the left. </p>

    <img src="/images/lessons/introduction-plugins/python-plugin-manager.png" alt="Manage your python plugins" />

    <p>What is listed are all the plugins that are available to Krita when it starts. The plugins that are checked are the ones that are active. The ones that are not active are not loaded. When you select a plugin, there is a description about it below.</p>

    <p>When you enable or disable plugins, you will need to restart Krita to see the changes take effect.</p>


    <h3>Conclusion</h3>

    <p>We had an overview of what plugins are and why they are useful. We also went over the types of plugins that you can make as well as how to manage them from the user interface in Krita. Next we we will get back into coding and start creating our first plugin.</p>



    <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>If your plugin isn't loading, check the python plugin area in the configuration. If the plugin is greyed out, that means there is some coding error happening. If you hover your mouse over the plugin, a tooltip will appear telling you where the error is happening.</p>
    </blockquote>


      </div>
    );
  }

}

export default LessonPluginsIntro;



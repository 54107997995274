import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  
  // useEffect is a "hook" in React that will be in effect as long as the component is running
  useEffect(() => {

    // this will keep "listen" to  URL changes in the browser. Do stuff when 
    // the browser changes
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten(); // component is done, so stop listening
    }
  }, [history]);

  return (null);
}

// We still import the class as "ScrollToTop", but the withRouter makes it
// so we also get the router information and get the browser history
export default withRouter(ScrollToTop);
import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
Krita.instance().action('help_about_app').trigger()
`;

const codeBlockTwo = `
Krita.instance().action('deselect').trigger()
`;

const codeBlockThree = `
Krita.instance().action('file_open').trigger()
`;


const codeBlockFour = `
# change location to your image
newDocument = Krita.instance().openDocument('C:\\sample.png') 

# shows document in application
Krita.instance().activeWindow().addView(newDocument)
`;

const codeBlockFive = `
Krita.instance().action('mirror_canvas').trigger()
`;


const codeBlockCreateAction = `
from PyQt5.QtWidgets import (QWidget, QAction)
import krita

def doCustomStuff():
    print("action did stuff")

#create an action and configure it
extractAction = QAction("Print a test statement to terminal")
extractAction.triggered.connect(doCustomStuff)

''' 
run the action for testing
custom actions are usually triggered only after button clicks
but this will work for our small test 
'''
extractAction.trigger()
`;




class LessonActions extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Using a simple action</h2>

        <p>While there are some things you can do by accessing the document and layers areas, other things can only be done with actions. Actions are predefined “things” Krita can do. Krita's main menu options are all associated with actions. The toolbar and toolbox buttons are associated with actions. They are a common way to access some functionality from multiple areas. Let's try this one out…</p>

       <PrismCode code={codeBlockOne} language="python" />
      
      <p>This will open the About Krita dialog. This is the same thing as going to the main menu <strong>Help &gt; About Krita</strong>. What this does is get an action wit the name "help_about_app", then it runs the action with the trigger() function.</p>
      
      <p>There are many actions, but many of them need a document open for them to work. For example if you try this out </p>

      <PrismCode code={codeBlockTwo} language="python" />

      <p>Did it work? The code will run, but nothing will probably happen. There will be no error stating something like there is no document open, or there is no selection. There area  lot of actions that will only do things in certain situations. This could be confusing and you  need to think a bit harder about when to use an action. Now try the same line of code above with a document open and after you make a selection.</p>

      <p>It de-selects the selection now. This "awareness" is the reason why so many main menu items in Krita are disabled if you have no document open. They won't do anything because there is no document. </p>

      <h3>Actions can only do "simple" things</h3>

      <p>When you call an action like above, you never add parameters like specifying a height, or telling the image how much to rotate. Actions are immediate. If they really need extra information, they will open up a dialog for you to put that in. For example.</p>

      <PrismCode code={codeBlockThree} language="python" />

      <p>Will start opening a file, but it will open a file dialog for you to actually select a file. If you would want to open a file and say what file to open, you cannot use the action. You would have to use the function off the Krita instance like this.</p>

      <PrismCode code={codeBlockFour} language="python" />

      <p>Knowing this, if you see something that can just be called automatically without any extra information, there is a good chance an action exists for it. If you need more control like configuring it with file names or numbers, actions are not going to work.</p>

      <h3>Action Dictionary</h3>

      <p>To see all the available actions you have access to, there is a handy tool on this site called the action dictionary. You can go the <Link to="/action-dictionary">action dictionary</Link> and it will allow you to search and browse a list of all the actions. </p>

      <p>To use the action dictionary start searching for something you want. Say we want to mirror the document to see it flipped. Type in "mirror" and see what actions are available. There are a number of mirror actions, but the one we want is called "Mirror View". For scripting, the important part to write down is the ID. The dictionary says the ID is "mirror_canvas". We can plug that into our action and it should work.</p>

      <PrismCode code={codeBlockFive} language="python" />

      <p>Of course we would need a document open and some stuff painted to see the result. </p>

 
      <h3>Create new actions</h3>

      <p>Some things that we will learn about later need actions to work such as adding items to menus. If an action doesn't exist in Krita, we will have to make our own action. This is how you would configure one.</p>

      <PrismCode code={codeBlockCreateAction} language="python" />

      <p>The order that we place this code is really important like other examples. If you try to move this code around in a different order, things might not work because variables will not have been defined. Actions are a part of the PyQt library. If you want to dig into actions more, do an internet search for QActions to see what is available. You might have to look at the PyQt5 API page if you are not sure how to look through API documentation.</p>

    
      </div>
    );
  }

}

export default LessonActions;



import React, { Component } from "react";
import AvailableActions from "./info-json";

class ActionDictionary extends Component {
  constructor() {
    super();

    this.state = {
      search: null,
    };
  }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  render() {

    const items = AvailableActions.filter((data) => {
      
      // return everything if nothing exists
      if (this.state.search == null) {
        return data;
      }      
      
      if (
        data.id.toLowerCase().includes(this.state.search.toLowerCase()) ||
        data.description.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return data;
      } 
     
      return null;

    })
      .sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1))
      .map((data, index) => {
        return (
          <div key={index} className="action-item">
            <div className="action-item-title">
              {data.description.replace("&", "")}
            </div>
            <div>id: {data.id}</div>
            <div>tooltip: {data.tooltip}</div>
          </div>
        );
      });

    return (
      <div>
        <div className="action-dictionary-page">
          <div className="main-content-wrapper">
            <h1>Action Dictionary</h1>
            <input
              id="action-searchbox"
              type="text"
              placeholder="Search for action by name or id"
              onChange={(e) => this.searchSpace(e)}
            />

            <em>{ items.length  } results</em>
            
            <div className="actions-container">
              {items}
            </div>

          </div>
        </div>
     
      </div>
    );
  }
}

export default ActionDictionary;

import React from "react";
import PrismCode from '../PrismCode'

//import {Link} from "react-router-dom";

const pyQtVersionCode = `
from PyQt5.Qt import PYQT_VERSION_STR
print("PyQt version:", PYQT_VERSION_STR)
`;

const qtPyQtConversion = `

# creating new objects
# Qt documentation says this... 
# QPushButton *button = new QPushButton("&Download", this);

# you would use it in PyQt like this
button = QButton("Download")

#############################################

# properties and assigning result
# Qt documentation says this...  
# flat : bool

# you would use it like this in PyQt
isIsFlat = button.flat

#############################################

# methods/functions
# Qt documentation says this, void QPushButton::showMenu()
# you would use it like this in PyQt
button.showMenu()
`;




class LessonApiPyQt extends React.Component {

  render() {
    return (
      <div>
        <h2>Digging deeper with PyQt </h2>

        <p>Through the lessons we began to look at some of what PyQt had to offer. Any time we did an import statement with PyQt5 at the start, we were using part of the PyQt5 library. PyQt is very large, so there are a lot more things that you can do that we haven't covered. Before we spend too much time looking at a large API, it is important to know what version of PyQt you are using. Functionality is added and changed all the time, so making sure you are looking at accurate documentation is really important.</p>


        <p>To find what version of PyQt you are using run this code in Scripter</p>

       <PrismCode code={pyQtVersionCode} language="python" />
      
      <p>As of this writing I am using PyQt5.14.1. The documentation we are looking at is for version 5.15, so that is close enough that very little will be changed.</p>

      <h3>Confusion finding the right documentation</h3>

      <p>If you spend much time looking for PyQt5 documentation, you will soon find out is more complicated than it probably should be. The issue is that there are multiple ways Python and Qt hook up to each other. There is also the C++ version just called Qt. Instead of spending a long time going over history or technical differences, it will probably be better to go over how developers currently look up and reference the PyQt API. </p>

      <p>The trick is to not search for PyQt documentation, but just Qt. PyQt closely mirrors the API of Qt, so looking at Qt documentation will be good enough. Qt is the C++ toolkit with what Krita is built on. The Qt documentation is quite good, so it is the best reference available. The main thing we will have to figure out is how to convert Qt code to PyQt code. And also learn how to navigate through the site.</p>

      <img src="/images/lessons/api-pyqt/qt-api-screenshot.png" alt="Qt API site" />

      <p>These are the PyQt/Qt modules that are available for us to use. It can be a bit overwhelming looking through the Qt documentation site, so we will go over things a bit slow if you haven't seen an API reference before.</p>

      <ul>
        <li>QtWidgets - https://doc.qt.io/qt-5/qtwidgets-module.html</li>
        <li>QtGui - https://doc.qt.io/qt-5/qtgui-module.html</li>
        <li>QtCore - https://doc.qt.io/qt-5/qtcore-module.html</li>
        <li>QtXML - https://doc.qt.io/qt-5/qtxml-module.html</li>
      </ul>

      <p>To get our bearing a bit better, take a look at the first link that lists all the QWidgets we have access to. If you look through the list, you will see a few familiar names like QPushButton and QLabel that we have used when making a dialog. Click the QPushButton link so we can see what it can do. </p>

      <p>Look through the documentation a bit for this class and see all the different things that QPushButtons can do. Believe it or not, a QPushButton can even do more than this. If you want to see a full list of everything a QPushButton can do, click the link near the top that says "List of all members, including inherited members". The page that we were on before are properties that are specific to the QPushButton. QPushbutton also has a lot of shared properties that other classes have through a concept called "inheritance".</p>

      <p>Every class like QPushButton is laid out in a similar page format. If you go back a few pages and do the same thing with QLabel, the page and content are laid out in the same format. Understanding how this API site is structured will help you move around and not feel as overwhelmed.</p>


      <h3>Converting Qt to PyQt </h3>

      <p>When you use the Qt documentation, you will have to think about how to change this code to work in PyQt. These are the type of conversations that you will have to think about when converting Qt to PyQt. If you haven't done C++ before, some of this might not be making sense.</p>

      <PrismCode code={qtPyQtConversion} language="python" />

      <p>A couple important differences with Python is that it does do memory management like C++, so there are no * symbols in PyQt. Also when you make a new button, you don't have to say what type of variable it is. That variable type is also removed in PyQt code. </p>


      </div>
    );
  }

}

export default LessonApiPyQt;



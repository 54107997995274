import React from "react";
import PrismCode from '../PrismCode'

// import {Link} from "react-router-dom";

const codeBlockOne = `
from .extension_template import ExtensionTemplate

Krita.instance().addExtension(ExtensionTemplate(Krita.instance()))
`;

const codeBlockTwo = `
from krita import *

class ExtensionTemplate(Extension):

    def __init__(self, parent):
        super().__init__(parent)

    # Krita.instance() exists, so do any setup work
    def setup(self):
        pass

    # called after setup(self)
    def createActions(self, window):
        pass
`;

const codeBlockAddMessageBox = `
from krita import *
from PyQt5.QtWidgets import QWidget, QAction, QMessageBox

class ExtensionTemplate(Extension):

    def __init__(self, parent):
        super().__init__(parent)

    def setup(self):
        pass

    def system_check(self):
        # QMessageBox creates quick popup with information
        messageBox = QMessageBox()
        messageBox.setInformativeText(Application.version())
        messageBox.setWindowTitle('System Check')
        messageBox.setText("Hello! Here is the version of Krita you are using.");
        messageBox.setStandardButtons(QMessageBox.Close)
        messageBox.setIcon(QMessageBox.Information)
        messageBox.exec()

    def createActions(self, window):
        action = window.createAction("", "System Check")
        action.triggered.connect(self.system_check)
`;


class LessonExtensions extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Non-docker plugins</h2>

        <p>Dockers are great plugins, but there are a lot of things that you might need that is more than a single docker. You might want to create a series of entries in the main menu that relate to your workflow. You also might want to create multiple windows and dialogs with one plugin for managing something. Extensions are great for this. Extensions are also plugins like dockers so the anatomy and structure of an Extension is very similar.</p>

        <p>To start with a blank template with an extension, you can start off with this blank extension template.</p>

        <a href="/images/lessons/plugins-extensions/extension_template.zip">Download blank extension template ZIP</a><br/><br/>

       <p>Unzip these contents and put it in the pykrita folder like the previous lesson went over. The extension will appear in the python plugin manager when you start Krita. Enable it to make sure it runs on the next Krita restart.</p>
      
        <p>The files for this template are named slightly different, but otherwise this template is almost pretty identical. The setup is a bit different if you go into the __init file.</p>

        <PrismCode code={codeBlockOne} language="python" />

        <p>This just says that we need to add an extension and point to the class that will have the logic (extension_template.py)</p>

        <h3>Main extension template class</h3>

        <p>An extension is a class like a Docker, but has a couple different functions that it uses by default. Let's open up our extension_template.py and see what it contains.</p>

        <PrismCode code={codeBlockTwo} language="python" />

        <p>Both the setup() and createActions() methods are called automatically while Krita is starting up. The setup() happens slightly before the createAction() happens, but both have the krita.instance() available for you to use to start working. The __init__ is called when the extension first loads. It is required that it has super().__init__(parent). If you try to remove that line you will notice your plugin will stop loading</p>

        
        <h3>Adding actions to your extension</h3>

        <p>Let's add an action item to our extension. This will add a main menu item in our <strong>Tools &gt; Scripts</strong> area that shows the version of Krita we are using. </p>

        <PrismCode code={codeBlockAddMessageBox} language="python" />



   
      </div>
    );
  }

}

export default LessonExtensions;



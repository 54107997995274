const AvailableActions = [
  {
    "id": "save_incremental_version",
    "description": "Save Incremental Version",
    "tooltip": "Save Incremental Version (Ctrl+Alt+S)"
  },
  {
    "id": "save_incremental_backup",
    "description": "Save Incremental Backup",
    "tooltip": "Save Incremental Backup (F4)"
  },
  {
    "id": "tablet_debugger",
    "description": "Toggle Tablet Debugger",
    "tooltip": "Toggle Tablet Debugger (Ctrl+Shift+T)"
  },
  {
    "id": "create_template",
    "description": "Create Template From Image...",
    "tooltip": "Create Template From Image"
  },
  {
    "id": "create_copy",
    "description": "Create Copy From Current Image",
    "tooltip": "Create Copy From Current Image"
  },
  {
    "id": "open_resources_directory",
    "description": "Open Resources Folder",
    "tooltip": "Opens a file browser at the location Krita saves resources such as brushes to."
  },
  {
    "id": "rotate_canvas_right",
    "description": "Rotate Canvas Right",
    "tooltip": "Rotate Canvas Right (Ctrl+])"
  },
  {
    "id": "rotate_canvas_left",
    "description": "Rotate Canvas Left",
    "tooltip": "Rotate Canvas Left (Ctrl+[)"
  },
  {
    "id": "reset_canvas_rotation",
    "description": "Reset Canvas Rotation",
    "tooltip": "Reset Canvas Rotation"
  },
  {
    "id": "wrap_around_mode",
    "description": "Wrap Around Mode",
    "tooltip": "Wrap Around Mode (Shift+W)"
  },
  {
    "id": "wrap_around_hv_axis",
    "description": "Wrap Around Both Directions",
    "tooltip": "Wrap Around Both Directions"
  },
  {
    "id": "wrap_around_h_axis",
    "description": "Wrap Around Horizontally",
    "tooltip": "Wrap Around Horizontally"
  },
  {
    "id": "wrap_around_v_axis",
    "description": "Wrap Around Vertically",
    "tooltip": "Wrap Around Vertically"
  },
  {
    "id": "level_of_detail_mode",
    "description": "Instant Preview Mode",
    "tooltip": "Instant Preview Mode (Shift+L)"
  },
  {
    "id": "softProof",
    "description": "Soft Proofing",
    "tooltip": "Turns on Soft Proofing (Ctrl+Y)"
  },
  {
    "id": "gamutCheck",
    "description": "Out of Gamut Warnings",
    "tooltip": "Turns on warnings for colors out of proofed gamut, needs soft proofing to be turned on. (Ctrl+Shift+Y)"
  },
  {
    "id": "showStatusBar",
    "description": "Show Status Bar",
    "tooltip": "Show or hide the status bar"
  },
  {
    "id": "view_show_canvas_only",
    "description": "Show Canvas Only",
    "tooltip": "Show just the canvas or the whole window (Tab)"
  },
  {
    "id": "ruler_pixel_multiple2",
    "description": "Use multiple of 2 for pixel scale",
    "tooltip": "Use multiple of 2 for pixel scale"
  },
  {
    "id": "view_ruler",
    "description": "Show Rulers",
    "tooltip": "Show Rulers"
  },
  {
    "id": "rulers_track_mouse",
    "description": "Rulers Track Pointer",
    "tooltip": "Rulers Track Pointer"
  },
  {
    "id": "zoom_to_100pct",
    "description": "Zoom to 100%",
    "tooltip": "Zoom to 100% (Ctrl+0)"
  },
  {
    "id": "view_zoom_in",
    "description": "Zoom In",
    "tooltip": "Zoom In (Ctrl++)"
  },
  {
    "id": "view_zoom_out",
    "description": "Zoom Out",
    "tooltip": "Zoom Out (Ctrl+-)"
  },
  {
    "id": "zoom_to_fit",
    "description": "Fit to View",
    "tooltip": "Fit Canvas to View"
  },
  {
    "id": "zoom_to_fit_width",
    "description": "Fit to View Width",
    "tooltip": "Fit Canvas to View Width"
  },
  {
    "id": "zoom_to_fit_height",
    "description": "Fit to View Height",
    "tooltip": "Fit Canvas to View Height"
  },
  {
    "id": "toggle_zoom_to_fit",
    "description": "Toggle Fit to View",
    "tooltip": "Toggle Fit to View"
  },
  {
    "id": "reset_display",
    "description": "Reset Display",
    "tooltip": "Reset zoom, rotation, and mirroring to their default states."
  },
  {
    "id": "view_print_size",
    "description": "View Print Size",
    "tooltip": "Map the canvas display to print size, based on the image resolution."
  },
  {
    "id": "settings_active_author",
    "description": "Active Author Profile",
    "tooltip": "Active Author Profile"
  },
  {
    "id": "view_pixel_grid",
    "description": "Show Pixel Grid",
    "tooltip": "Show Pixel Grid"
  },
  {
    "id": "toggle_fg_bg",
    "description": "Swap Foreground and Background Colors",
    "tooltip": "Swap foreground and background colors (X)"
  },
  {
    "id": "reset_fg_bg",
    "description": "Set Foreground and Background Colors to Black and White",
    "tooltip": "Set foreground and background colors to black and white (D)"
  },
  {
    "id": "toggle_brush_outline",
    "description": "Toggle Brush Outline",
    "tooltip": "Disable the brush outline, or enable the previously used brush outline shape if already disabled"
  },
  {
    "id": "filter_apply_again",
    "description": "Apply Filter Again",
    "tooltip": "Apply Filter Again (Ctrl+F)"
  },
  {
    "id": "filter_apply_reprompt",
    "description": "Apply Filter Again (Reprompt)",
    "tooltip": "Reapply Last Used Filter with Prompt for Configuration"
  },
  {
    "id": "adjust_filters",
    "description": "Adjust",
    "tooltip": "Adjust"
  },
  {
    "id": "krita_filter_asc-cdl",
    "description": "Slope, Offset, Power...",
    "tooltip": "Slope, Offset, Power Filter"
  },
  {
    "id": "krita_filter_autocontrast",
    "description": "Auto Contrast",
    "tooltip": "Auto Contrast"
  },
  {
    "id": "blur_filters",
    "description": "Blur",
    "tooltip": "Blur"
  },
  {
    "id": "krita_filter_blur",
    "description": "Blur...",
    "tooltip": "Blur"
  },
  {
    "id": "krita_filter_burn",
    "description": "Burn...",
    "tooltip": "Burn"
  },
  {
    "id": "krita_filter_colorbalance",
    "description": "Color Balance...",
    "tooltip": "Color Balance (Ctrl+B)"
  },
  {
    "id": "color_filters",
    "description": "Colors",
    "tooltip": "Colors"
  },
  {
    "id": "krita_filter_colortoalpha",
    "description": "Color to Alpha...",
    "tooltip": "Color to Alpha"
  },
  {
    "id": "krita_filter_colortransfer",
    "description": "Color Transfer...",
    "tooltip": "Color Transfer"
  },
  {
    "id": "krita_filter_crosschannel",
    "description": "Cross-channel adjustment curves...",
    "tooltip": "Cross-channel adjustment Filter"
  },
  {
    "id": "krita_filter_desaturate",
    "description": "Desaturate...",
    "tooltip": "Desaturate (Ctrl+Shift+U)"
  },
  {
    "id": "krita_filter_dodge",
    "description": "Dodge...",
    "tooltip": "Dodge"
  },
  {
    "id": "edge_filters",
    "description": "Edge Detection",
    "tooltip": "Edge Detection"
  },
  {
    "id": "krita_filter_edge detection",
    "description": "Edge Detection...",
    "tooltip": "Edge Detection"
  },
  {
    "id": "emboss_filters",
    "description": "Emboss",
    "tooltip": "Emboss"
  },
  {
    "id": "krita_filter_emboss",
    "description": "Emboss with Variable Depth...",
    "tooltip": "Emboss with Variable Depth"
  },
  {
    "id": "krita_filter_emboss all directions",
    "description": "Emboss in All Directions",
    "tooltip": "Emboss in All Directions"
  },
  {
    "id": "krita_filter_emboss horizontal and vertical",
    "description": "Emboss Horizontal  Vertical",
    "tooltip": "Emboss Horizontal & Vertical"
  },
  {
    "id": "krita_filter_emboss horizontal only",
    "description": "Emboss Horizontal Only",
    "tooltip": "Emboss Horizontal Only"
  },
  {
    "id": "krita_filter_emboss laplascian",
    "description": "Emboss (Laplacian)",
    "tooltip": "Emboss (Laplacian)"
  },
  {
    "id": "krita_filter_emboss vertical only",
    "description": "Emboss Vertical Only",
    "tooltip": "Emboss Vertical Only"
  },
  {
    "id": "krita_filter_gaussian blur",
    "description": "Gaussian Blur...",
    "tooltip": "Gaussian Blur"
  },
  {
    "id": "krita_filter_gaussianhighpass",
    "description": "Gaussian High Pass...",
    "tooltip": "Gaussian High Pass Filter"
  },
  {
    "id": "enhance_filters",
    "description": "Enhance",
    "tooltip": "Enhance"
  },
  {
    "id": "krita_filter_gaussiannoisereducer",
    "description": "Gaussian Noise Reduction...",
    "tooltip": "Gaussian Noise Reduction"
  },
  {
    "id": "map_filters",
    "description": "Map",
    "tooltip": "Map"
  },
  {
    "id": "krita_filter_gradientmap",
    "description": "Gradient Map...",
    "tooltip": "Gradient Map Filter"
  },
  {
    "id": "artistic_filters",
    "description": "Artistic",
    "tooltip": "Artistic"
  },
  {
    "id": "krita_filter_halftone",
    "description": "Halftone...",
    "tooltip": "Halftone Filter"
  },
  {
    "id": "krita_filter_height to normal",
    "description": "Height to Normal Map...",
    "tooltip": "Height to Normal Map Filter"
  },
  {
    "id": "krita_filter_hsvadjustment",
    "description": "HSV Adjustment...",
    "tooltip": "HSV Adjustment (Ctrl+U)"
  },
  {
    "id": "krita_filter_indexcolors",
    "description": "Index Colors...",
    "tooltip": "Index Colors"
  },
  {
    "id": "krita_filter_invert",
    "description": "Invert",
    "tooltip": "Invert (Ctrl+I)"
  },
  {
    "id": "krita_filter_lens blur",
    "description": "Lens Blur...",
    "tooltip": "Lens Blur"
  },
  {
    "id": "krita_filter_levels",
    "description": "Levels...",
    "tooltip": "Levels (Ctrl+L)"
  },
  {
    "id": "krita_filter_maximize",
    "description": "Maximize Channel",
    "tooltip": "Maximize Channel"
  },
  {
    "id": "krita_filter_mean removal",
    "description": "Mean Removal",
    "tooltip": "Mean Removal"
  },
  {
    "id": "krita_filter_minimize",
    "description": "Minimize Channel",
    "tooltip": "Minimize Channel"
  },
  {
    "id": "krita_filter_motion blur",
    "description": "Motion Blur...",
    "tooltip": "Motion Blur"
  },
  {
    "id": "other_filters",
    "description": "Other",
    "tooltip": "Other"
  },
  {
    "id": "krita_filter_noise",
    "description": "Random Noise...",
    "tooltip": "Random Noise"
  },
  {
    "id": "krita_filter_normalize",
    "description": "Normalize",
    "tooltip": "Normalize Filter"
  },
  {
    "id": "krita_filter_oilpaint",
    "description": "Oilpaint...",
    "tooltip": "Oilpaint"
  },
  {
    "id": "krita_filter_palettize",
    "description": "Palettize...",
    "tooltip": "Palettize Filter"
  },
  {
    "id": "krita_filter_perchannel",
    "description": "Color Adjustment curves...",
    "tooltip": "Color Adjustment curves (Ctrl+M)"
  },
  {
    "id": "krita_filter_phongbumpmap",
    "description": "Phong Bumpmap...",
    "tooltip": "Phong Bumpmap"
  },
  {
    "id": "krita_filter_pixelize",
    "description": "Pixelize...",
    "tooltip": "Pixelize"
  },
  {
    "id": "krita_filter_posterize",
    "description": "Posterize...",
    "tooltip": "Posterize"
  },
  {
    "id": "krita_filter_raindrops",
    "description": "Raindrops...",
    "tooltip": "Raindrops"
  },
  {
    "id": "krita_filter_randompick",
    "description": "Random Pick...",
    "tooltip": "Random Pick"
  },
  {
    "id": "krita_filter_resettransparent",
    "description": "Reset Transparent",
    "tooltip": "Reset Transparent"
  },
  {
    "id": "krita_filter_roundcorners",
    "description": "Round Corners...",
    "tooltip": "Round Corners"
  },
  {
    "id": "krita_filter_sharpen",
    "description": "Sharpen",
    "tooltip": "Sharpen"
  },
  {
    "id": "krita_filter_smalltiles",
    "description": "Small Tiles...",
    "tooltip": "Small Tiles"
  },
  {
    "id": "krita_filter_threshold",
    "description": "Threshold...",
    "tooltip": "Threshold Filter"
  },
  {
    "id": "krita_filter_unsharp",
    "description": "Unsharp Mask...",
    "tooltip": "Unsharp Mask"
  },
  {
    "id": "krita_filter_wave",
    "description": "Wave...",
    "tooltip": "Wave"
  },
  {
    "id": "krita_filter_waveletnoisereducer",
    "description": "Wavelet Noise Reducer...",
    "tooltip": "Wavelet Noise Reducer"
  },
  {
    "id": "edit_cut",
    "description": "Cut",
    "tooltip": "Cut selection to clipboard (Ctrl+X)"
  },
  {
    "id": "edit_copy",
    "description": "Copy",
    "tooltip": "Copy selection to clipboard (Ctrl+C)"
  },
  {
    "id": "edit_paste",
    "description": "Paste",
    "tooltip": "Paste clipboard content (Ctrl+V)"
  },
  {
    "id": "copy_sharp",
    "description": "Copy (sharp)",
    "tooltip": "Copy (sharp)"
  },
  {
    "id": "cut_sharp",
    "description": "Cut (sharp)",
    "tooltip": "Cut (sharp)"
  },
  {
    "id": "paste_new",
    "description": "Paste into New Image",
    "tooltip": "Paste into New Image (Ctrl+Shift+N)"
  },
  {
    "id": "paste_at",
    "description": "Paste at Cursor",
    "tooltip": "Paste at cursor (Ctrl+Alt+V)"
  },
  {
    "id": "paste_into",
    "description": "Paste into Active Layer",
    "tooltip": "Paste into Active Layer"
  },
  {
    "id": "paste_as_reference",
    "description": "Paste as Reference Image",
    "tooltip": "Paste as Reference Image (Ctrl+Shift+R)"
  },
  {
    "id": "paste_shape_style",
    "description": "Paste Shape Style",
    "tooltip": "Paste Shape Style"
  },
  {
    "id": "copy_merged",
    "description": "Copy merged",
    "tooltip": "Copy merged (Ctrl+Shift+C)"
  },
  {
    "id": "select_all",
    "description": "Select All",
    "tooltip": "Select All (Ctrl+A)"
  },
  {
    "id": "deselect",
    "description": "Deselect",
    "tooltip": "Deselect (Ctrl+Shift+A)"
  },
  {
    "id": "clear",
    "description": "Clear",
    "tooltip": "Clear (Del)"
  },
  {
    "id": "reselect",
    "description": "Reselect",
    "tooltip": "Reselect (Ctrl+Shift+D)"
  },
  {
    "id": "invert_selection",
    "description": "Invert Selection",
    "tooltip": "Invert current selection (Ctrl+Shift+I)"
  },
  {
    "id": "copy_selection_to_new_layer",
    "description": "Copy Selection to New Layer",
    "tooltip": "Copy Selection to New Layer (Ctrl+Alt+J)"
  },
  {
    "id": "cut_selection_to_new_layer",
    "description": "Cut Selection to New Layer",
    "tooltip": "Cut Selection to New Layer (Ctrl+Shift+J)"
  },
  {
    "id": "fill_selection_foreground_color",
    "description": "Fill with Foreground Color",
    "tooltip": "Fill with Foreground Color (Shift+Backspace)"
  },
  {
    "id": "fill_selection_background_color",
    "description": "Fill with Background Color",
    "tooltip": "Fill with Background Color (Backspace)"
  },
  {
    "id": "fill_selection_pattern",
    "description": "Fill with Pattern",
    "tooltip": "Fill with Pattern"
  },
  {
    "id": "fill_selection_foreground_color_opacity",
    "description": "Fill with Foreground Color (Opacity)",
    "tooltip": "Fill with Foreground Color (Opacity) (Ctrl+Shift+Backspace)"
  },
  {
    "id": "fill_selection_background_color_opacity",
    "description": "Fill with Background Color (Opacity)",
    "tooltip": "Fill with Background Color (Opacity) (Ctrl+Backspace)"
  },
  {
    "id": "fill_selection_pattern_opacity",
    "description": "Fill with Pattern (Opacity)",
    "tooltip": "Fill with Pattern (Opacity)"
  },
  {
    "id": "stroke_shapes",
    "description": "Stroke selected shapes",
    "tooltip": "Stroke selected shapes"
  },
  {
    "id": "toggle_display_selection",
    "description": "Display Selection",
    "tooltip": "Display Selection (Ctrl+H)"
  },
  {
    "id": "resizeimagetoselection",
    "description": "Trim to Selection",
    "tooltip": "Trim to Selection"
  },
  {
    "id": "edit_selection",
    "description": "Edit Selection",
    "tooltip": "Edit Selection"
  },
  {
    "id": "convert_to_vector_selection",
    "description": "Convert to Vector Selection",
    "tooltip": "Convert to Vector Selection"
  },
  {
    "id": "convert_to_raster_selection",
    "description": "Convert to Raster Selection",
    "tooltip": "Convert to Raster Selection"
  },
  {
    "id": "convert_shapes_to_vector_selection",
    "description": "Convert Shapes to Vector Selection",
    "tooltip": "Convert Shapes to Vector Selection"
  },
  {
    "id": "convert_selection_to_shape",
    "description": "Convert to Shape",
    "tooltip": "Convert to Shape"
  },
  {
    "id": "toggle-selection-overlay-mode",
    "description": "Toggle Selection Display Mode",
    "tooltip": "Toggle Selection Display Mode"
  },
  {
    "id": "stroke_selection",
    "description": "Stroke Selection...",
    "tooltip": "Stroke selection"
  },
  {
    "id": "view_show_guides",
    "description": "Show Guides",
    "tooltip": "Show or hide guides"
  },
  {
    "id": "view_lock_guides",
    "description": "Lock Guides",
    "tooltip": "Lock or unlock guides"
  },
  {
    "id": "view_snap_to_guides",
    "description": "Snap to Guides",
    "tooltip": "Snap cursor to guides position"
  },
  {
    "id": "show_snap_options_popup",
    "description": "Show Snap Options Popup",
    "tooltip": "Show Snap Options Popup (Shift+S)"
  },
  {
    "id": "view_snap_orthogonal",
    "description": "Snap Orthogonal",
    "tooltip": "Snap Orthogonal"
  },
  {
    "id": "view_snap_node",
    "description": "Snap Node",
    "tooltip": "Snap Node"
  },
  {
    "id": "view_snap_extension",
    "description": "Snap Extension",
    "tooltip": "Snap Extension"
  },
  {
    "id": "view_snap_intersection",
    "description": "Snap Intersection",
    "tooltip": "Snap Intersection"
  },
  {
    "id": "view_snap_bounding_box",
    "description": "Snap Bounding Box",
    "tooltip": "Snap Bounding Box"
  },
  {
    "id": "view_snap_image_bounds",
    "description": "Snap Image Bounds",
    "tooltip": "Snap Image Bounds"
  },
  {
    "id": "view_snap_image_center",
    "description": "Snap Image Center",
    "tooltip": "Snap Image Center"
  },
  {
    "id": "view_snap_to_pixel",
    "description": "Snap Pixel",
    "tooltip": "Snap Pixel"
  },
  {
    "id": "flatten_image",
    "description": "Flatten image",
    "tooltip": "Flatten image (Ctrl+Shift+E)"
  },
  {
    "id": "merge_layer",
    "description": "Merge with Layer Below",
    "tooltip": "Merge with Layer Below (Ctrl+E)"
  },
  {
    "id": "flatten_layer",
    "description": "Flatten Layer",
    "tooltip": "Flatten Layer"
  },
  {
    "id": "save_groups_as_images",
    "description": "Save Group Layers...",
    "tooltip": "Save Group Layers"
  },
  {
    "id": "convert_group_to_animated",
    "description": "Convert group to animated layer",
    "tooltip": "Convert child layers into animation frames"
  },
  {
    "id": "resizeimagetolayer",
    "description": "Trim to Current Layer",
    "tooltip": "Trim to Current Layer"
  },
  {
    "id": "trim_to_image",
    "description": "Trim to Image Size",
    "tooltip": "Trim to Image Size"
  },
  {
    "id": "layer_style",
    "description": "Layer Style...",
    "tooltip": "Layer Style"
  },
  {
    "id": "copy_layer_style",
    "description": "Copy Layer Style",
    "tooltip": "Copy Layer Style from the Current Node"
  },
  {
    "id": "paste_layer_style",
    "description": "Paste Layer Style",
    "tooltip": "Paste Layer Style into the Current Node"
  },
  {
    "id": "mirrorNodeX",
    "description": "Mirror Layer Horizontally",
    "tooltip": "Mirror Layer Horizontally"
  },
  {
    "id": "mirrorNodeY",
    "description": "Mirror Layer Vertically",
    "tooltip": "Mirror Layer Vertically"
  },
  {
    "id": "mirrorAllNodesX",
    "description": "Mirror All Layers Horizontally",
    "tooltip": "Mirror All Layers Horizontally"
  },
  {
    "id": "mirrorAllNodesY",
    "description": "Mirror All Layers Vertically",
    "tooltip": "Mirror All Layers Vertically"
  },
  {
    "id": "activateNextLayer",
    "description": "Activate next layer",
    "tooltip": "Activate next layer (PgUp)"
  },
  {
    "id": "activateNextSiblingLayer",
    "description": "Activate next sibling layer, skipping over groups.",
    "tooltip": "Activate next sibling layer"
  },
  {
    "id": "activatePreviousLayer",
    "description": "Activate previous layer",
    "tooltip": "Activate previous layer (PgDown)"
  },
  {
    "id": "activatePreviousSiblingLayer",
    "description": "Activate previous sibling layer, skipping over groups.",
    "tooltip": "Activate previous sibling layer"
  },
  {
    "id": "switchToPreviouslyActiveNode",
    "description": "Activate previously selected layer",
    "tooltip": "Activate previously selected layer (;)"
  },
  {
    "id": "save_node_as_image",
    "description": "Save Layer/Mask...",
    "tooltip": "Save Layer/Mask"
  },
  {
    "id": "save_vector_node_to_svg",
    "description": "Save Vector Layer as SVG...",
    "tooltip": "Save Vector Layer as SVG"
  },
  {
    "id": "duplicatelayer",
    "description": "Duplicate Layer or Mask",
    "tooltip": "Duplicate Layer or Mask (Ctrl+J)"
  },
  {
    "id": "copy_layer_clipboard",
    "description": "Copy Layer",
    "tooltip": "Copy layer to clipboard"
  },
  {
    "id": "cut_layer_clipboard",
    "description": "Cut Layer",
    "tooltip": "Cut layer to clipboard"
  },
  {
    "id": "paste_layer_from_clipboard",
    "description": "Paste Layer",
    "tooltip": "Paste layer from clipboard"
  },
  {
    "id": "create_quick_group",
    "description": "Quick Group",
    "tooltip": "Create a group layer containing selected layers (Ctrl+G)"
  },
  {
    "id": "create_quick_clipping_group",
    "description": "Quick Clipping Group",
    "tooltip": "Group selected layers and add a layer with clipped alpha channel (Ctrl+Shift+G)"
  },
  {
    "id": "quick_ungroup",
    "description": "Quick Ungroup",
    "tooltip": "Remove grouping of the layers or remove one layer out of the group (Ctrl+Alt+G)"
  },
  {
    "id": "select_all_layers",
    "description": "All Layers",
    "tooltip": "Select all layers"
  },
  {
    "id": "select_visible_layers",
    "description": "Visible Layers",
    "tooltip": "Select all visible layers"
  },
  {
    "id": "select_locked_layers",
    "description": "Locked Layers",
    "tooltip": "Select all locked layers"
  },
  {
    "id": "select_invisible_layers",
    "description": "Invisible Layers",
    "tooltip": "Select all invisible layers"
  },
  {
    "id": "select_unlocked_layers",
    "description": "Unlocked Layers",
    "tooltip": "Select all unlocked layers"
  },
  {
    "id": "new_from_visible",
    "description": "New Layer From Visible",
    "tooltip": "New layer from visible"
  },
  {
    "id": "pin_to_timeline",
    "description": "Pin to Timeline",
    "tooltip": "If checked, layer becomes pinned to the timeline, making it visible even when inactive."
  },
  {
    "id": "add_new_paint_layer",
    "description": "Add Paint Layer",
    "tooltip": "Add a new Paint Layer (Ins)"
  },
  {
    "id": "add_new_group_layer",
    "description": "Add Group Layer",
    "tooltip": "Add a new Group Layer"
  },
  {
    "id": "add_new_clone_layer",
    "description": "Add Clone Layer",
    "tooltip": "Add a new Clone Layer"
  },
  {
    "id": "add_new_shape_layer",
    "description": "Add Vector Layer",
    "tooltip": "Add a new Vector Layer (Shift+Ins)"
  },
  {
    "id": "add_new_adjustment_layer",
    "description": "Add Filter Layer...",
    "tooltip": "Add a new Filter Layer"
  },
  {
    "id": "add_new_fill_layer",
    "description": "Add Fill Layer...",
    "tooltip": "Add a new Fill Layer"
  },
  {
    "id": "add_new_file_layer",
    "description": "Add File Layer...",
    "tooltip": "Add a new File Layer"
  },
  {
    "id": "add_new_transparency_mask",
    "description": "Add Transparency Mask",
    "tooltip": "Add a new Transparency Mask"
  },
  {
    "id": "add_new_filter_mask",
    "description": "Add Filter Mask...",
    "tooltip": "Add a new Filter Mask"
  },
  {
    "id": "add_new_colorize_mask",
    "description": "Add Colorize Mask",
    "tooltip": "Add a new Colorize Mask"
  },
  {
    "id": "add_new_transform_mask",
    "description": "Add Transform Mask",
    "tooltip": "Add a new Transform Mask"
  },
  {
    "id": "add_new_selection_mask",
    "description": "Add Local Selection",
    "tooltip": "Add a new Local Selection"
  },
  {
    "id": "convert_to_paint_layer",
    "description": "Convert to Paint Layer",
    "tooltip": "to Paint Layer"
  },
  {
    "id": "convert_to_selection_mask",
    "description": "Convert to Selection Mask",
    "tooltip": "to Selection Mask"
  },
  {
    "id": "convert_to_filter_mask",
    "description": "Convert to Filter Mask...",
    "tooltip": "to Filter Mask"
  },
  {
    "id": "convert_to_transparency_mask",
    "description": "Convert to Transparency Mask",
    "tooltip": "to Transparency Mask"
  },
  {
    "id": "convert_to_animated",
    "description": "Convert to animated layer",
    "tooltip": "Convert layer into animation frames"
  },
  {
    "id": "convert_to_file_layer",
    "description": "to File Layer...",
    "tooltip": "Saves out the layers into a new image and then references that image."
  },
  {
    "id": "isolate_active_layer",
    "description": "Isolate Active Layer",
    "tooltip": "Isolate Active Layer"
  },
  {
    "id": "isolate_active_group",
    "description": "Isolate Active Group",
    "tooltip": "Isolate Active Group"
  },
  {
    "id": "toggle_layer_visibility",
    "description": "Toggle layer visibility",
    "tooltip": "Toggle layer visibility"
  },
  {
    "id": "toggle_layer_lock",
    "description": "Toggle layer lock",
    "tooltip": "Toggle layer lock"
  },
  {
    "id": "toggle_layer_inherit_alpha",
    "description": "Toggle layer alpha inheritance",
    "tooltip": "Toggle layer alpha inheritance"
  },
  {
    "id": "toggle_layer_alpha_lock",
    "description": "Toggle layer alpha",
    "tooltip": "Toggle layer alpha"
  },
  {
    "id": "split_alpha_into_mask",
    "description": "Alpha into Mask",
    "tooltip": "Alpha into Mask"
  },
  {
    "id": "split_alpha_write",
    "description": "Write as Alpha",
    "tooltip": "Write as Alpha"
  },
  {
    "id": "split_alpha_save_merged",
    "description": "Save Merged...",
    "tooltip": "Save Merged"
  },
  {
    "id": "import_layer_from_file",
    "description": "Import Layer...",
    "tooltip": "Import Layer"
  },
  {
    "id": "image_properties",
    "description": "Properties...",
    "tooltip": "Properties"
  },
  {
    "id": "import_layer_as_paint_layer",
    "description": "as Paint Layer...",
    "tooltip": "as Paint Layer"
  },
  {
    "id": "import_layer_as_transparency_mask",
    "description": "as Transparency Mask...",
    "tooltip": "as Transparency Mask"
  },
  {
    "id": "import_layer_as_filter_mask",
    "description": "as Filter Mask...",
    "tooltip": "as Filter Mask"
  },
  {
    "id": "import_layer_as_selection_mask",
    "description": "as Selection Mask...",
    "tooltip": "as Selection Mask"
  },
  {
    "id": "image_color",
    "description": "Image Background Color and Transparency...",
    "tooltip": "Change the background color of the image"
  },
  {
    "id": "view_grid",
    "description": "Show Grid",
    "tooltip": "Show Grid (Ctrl+Shift+')"
  },
  {
    "id": "view_snap_to_grid",
    "description": "Snap To Grid",
    "tooltip": "Snap To Grid (Ctrl+Shift+;)"
  },
  {
    "id": "view_toggle_painting_assistants",
    "description": "Show Painting Assistants",
    "tooltip": "Show Painting Assistants"
  },
  {
    "id": "view_toggle_assistant_previews",
    "description": "Show Assistant Previews",
    "tooltip": "Show Assistant Previews"
  },
  {
    "id": "view_toggle_reference_images",
    "description": "Show Reference Images",
    "tooltip": "Show Reference Images"
  },
  {
    "id": "make_brush_color_lighter",
    "description": "Make brush color lighter",
    "tooltip": "Make brush color lighter (L)"
  },
  {
    "id": "make_brush_color_darker",
    "description": "Make brush color darker",
    "tooltip": "Make brush color darker (K)"
  },
  {
    "id": "make_brush_color_saturated",
    "description": "Make brush color more saturated",
    "tooltip": "Make brush color more saturated"
  },
  {
    "id": "make_brush_color_desaturated",
    "description": "Make brush color more desaturated",
    "tooltip": "Make brush color more desaturated"
  },
  {
    "id": "shift_brush_color_clockwise",
    "description": "Shift brush color hue clockwise",
    "tooltip": "Shift brush color hue clockwise"
  },
  {
    "id": "shift_brush_color_counter_clockwise",
    "description": "Shift brush color hue counter-clockwise",
    "tooltip": "Shift brush color hue counter-clockwise"
  },
  {
    "id": "make_brush_color_redder",
    "description": "Make brush color more red",
    "tooltip": "Make brush color more red"
  },
  {
    "id": "make_brush_color_greener",
    "description": "Make brush color more green",
    "tooltip": "Make brush color more green"
  },
  {
    "id": "make_brush_color_bluer",
    "description": "Make brush color more blue",
    "tooltip": "Make brush color more blue"
  },
  {
    "id": "make_brush_color_yellower",
    "description": "Make brush color more yellow",
    "tooltip": "Make brush color more yellow"
  },
  {
    "id": "increase_opacity",
    "description": "Increase Opacity",
    "tooltip": "Increase brush Opacity (O)"
  },
  {
    "id": "decrease_opacity",
    "description": "Decrease Opacity",
    "tooltip": "Decrease brush Opacity (I)"
  },
  {
    "id": "increase_flow",
    "description": "Increase Flow",
    "tooltip": "Increase brush Flow"
  },
  {
    "id": "decrease_flow",
    "description": "Decrease Flow",
    "tooltip": "Decrease brush Flow"
  },
  {
    "id": "increase_fade",
    "description": "Increase Fade",
    "tooltip": "Increase brush Fade"
  },
  {
    "id": "decrease_fade",
    "description": "Decrease Fade",
    "tooltip": "Decrease brush Fade"
  },
  {
    "id": "increase_scatter",
    "description": "Increase Scatter",
    "tooltip": "Increase brush Scatter strength"
  },
  {
    "id": "decrease_scatter",
    "description": "Decrease Scatter",
    "tooltip": "Decrease brush Scatter strength"
  },
  {
    "id": "mirror_canvas",
    "description": "Mirror View",
    "tooltip": "Mirror View (M)"
  },
  {
    "id": "mirror_canvas_around_cursor",
    "description": "Mirror View Around Cursor",
    "tooltip": "Mirror View Around Cursor (Alt+M)"
  },
  {
    "id": "mirror_canvas_around_canvas",
    "description": "Mirror View Around Canvas",
    "tooltip": "Mirror View Around Canvas"
  },
  {
    "id": "patterns",
    "description": "Patterns",
    "tooltip": "Patterns"
  },
  {
    "id": "gradients",
    "description": "Gradients",
    "tooltip": "Gradients"
  },
  {
    "id": "dual",
    "description": "Choose foreground and background colors",
    "tooltip": "Color"
  },
  {
    "id": "erase_action",
    "description": "Set eraser mode",
    "tooltip": "Set eraser mode (E)"
  },
  {
    "id": "eraser_preset_action",
    "description": "Toggle eraser preset",
    "tooltip": "Toggle eraser preset"
  },
  {
    "id": "reload_preset_action",
    "description": "Reload Original Preset",
    "tooltip": "Reload Original Preset"
  },
  {
    "id": "preserve_alpha",
    "description": "Preserve Alpha",
    "tooltip": "Preserve Alpha"
  },
  {
    "id": "mirrorX-hideDecorations",
    "description": "Hide Mirror X Line",
    "tooltip": "Hide Mirror X Line"
  },
  {
    "id": "mirrorX-lock",
    "description": "Lock X Line",
    "tooltip": "Lock X Line"
  },
  {
    "id": "mirrorX-moveToCenter",
    "description": "Move to Canvas Center X",
    "tooltip": "Move to Canvas Center X"
  },
  {
    "id": "mirrorY-hideDecorations",
    "description": "Hide Mirror Y Line",
    "tooltip": "Hide Mirror Y Line"
  },
  {
    "id": "mirrorY-lock",
    "description": "Lock Y Line",
    "tooltip": "Lock Y Line"
  },
  {
    "id": "mirrorY-moveToCenter",
    "description": "Move to Canvas Center Y",
    "tooltip": "Move to Canvas Center Y"
  },
  {
    "id": "hmirror_action",
    "description": "Horizontal Mirror Tool",
    "tooltip": "Horizontal Mirror Tool"
  },
  {
    "id": "vmirror_action",
    "description": "Vertical Mirror Tool",
    "tooltip": "Vertical Mirror Tool"
  },
  {
    "id": "Next Blending Mode",
    "description": "Next Blending Mode",
    "tooltip": "Next Blending Mode (Alt+Shift++)"
  },
  {
    "id": "Previous Blending Mode",
    "description": "Previous Blending Mode",
    "tooltip": "Previous Blending Mode (Alt+Shift+-)"
  },
  {
    "id": "Select Normal Blending Mode",
    "description": "Select Normal Blending Mode",
    "tooltip": "Select Normal Blending Mode (Alt+Shift+N)"
  },
  {
    "id": "Select Dissolve Blending Mode",
    "description": "Select Dissolve Blending Mode",
    "tooltip": "Select Dissolve Blending Mode (Alt+Shift+I)"
  },
  {
    "id": "Select Behind Blending Mode",
    "description": "Select Behind Blending Mode",
    "tooltip": "Select Behind Blending Mode (Alt+Shift+Q)"
  },
  {
    "id": "Select Clear Blending Mode",
    "description": "Select Clear Blending Mode",
    "tooltip": "Select Clear Blending Mode (Alt+Shift+R)"
  },
  {
    "id": "Select Darken Blending Mode",
    "description": "Select Darken Blending Mode",
    "tooltip": "Select Darken Blending Mode (Alt+Shift+K)"
  },
  {
    "id": "Select Multiply Blending Mode",
    "description": "Select Multiply Blending Mode",
    "tooltip": "Select Multiply Blending Mode (Alt+Shift+M)"
  },
  {
    "id": "Select Color Burn Blending Mode",
    "description": "Select Color Burn Blending Mode",
    "tooltip": "Select Color Burn Blending Mode (Alt+Shift+B)"
  },
  {
    "id": "Select Linear Burn Blending Mode",
    "description": "Select Linear Burn Blending Mode",
    "tooltip": "Select Linear Burn Blending Mode (Alt+Shift+A)"
  },
  {
    "id": "Select Lighten Blending Mode",
    "description": "Select Lighten Blending Mode",
    "tooltip": "Select Lighten Blending Mode (Alt+Shift+G)"
  },
  {
    "id": "Select Screen Blending Mode",
    "description": "Select Screen Blending Mode",
    "tooltip": "Select Screen Blending Mode (Alt+Shift+S)"
  },
  {
    "id": "Select Color Dodge Blending Mode",
    "description": "Select Color Dodge Blending Mode",
    "tooltip": "Select Color Dodge Blending Mode (Alt+Shift+D)"
  },
  {
    "id": "Select Linear Dodge Blending Mode",
    "description": "Select Linear Dodge Blending Mode",
    "tooltip": "Select Linear Dodge Blending Mode (Alt+Shift+W)"
  },
  {
    "id": "Select Overlay Blending Mode",
    "description": "Select Overlay Blending Mode",
    "tooltip": "Select Overlay Blending Mode (Alt+Shift+O)"
  },
  {
    "id": "Select Hard Overlay Blending Mode",
    "description": "Select Hard Overlay Blending Mode",
    "tooltip": "Select Hard Overlay Blending Mode (Alt+Shift+P)"
  },
  {
    "id": "Select Soft Light Blending Mode",
    "description": "Select Soft Light Blending Mode",
    "tooltip": "Select Soft Light Blending Mode (Alt+Shift+F)"
  },
  {
    "id": "Select Hard Light Blending Mode",
    "description": "Select Hard Light Blending Mode",
    "tooltip": "Select Hard Light Blending Mode (Alt+Shift+H)"
  },
  {
    "id": "Select Vivid Light Blending Mode",
    "description": "Select Vivid Light Blending Mode",
    "tooltip": "Select Vivid Light Blending Mode (Alt+Shift+V)"
  },
  {
    "id": "Select Linear Light Blending Mode",
    "description": "Select Linear Light Blending Mode",
    "tooltip": "Select Linear Light Blending Mode (Alt+Shift+J)"
  },
  {
    "id": "Select Pin Light Blending Mode",
    "description": "Select Pin Light Blending Mode",
    "tooltip": "Select Pin Light Blending Mode (Alt+Shift+Z)"
  },
  {
    "id": "Select Hard Mix Blending Mode",
    "description": "Select Hard Mix Blending Mode",
    "tooltip": "Select Hard Mix Blending Mode (Alt+Shift+L)"
  },
  {
    "id": "Select Difference Blending Mode",
    "description": "Select Difference Blending Mode",
    "tooltip": "Select Difference Blending Mode (Alt+Shift+E)"
  },
  {
    "id": "Select Exclusion Blending Mode",
    "description": "Select Exclusion Blending Mode",
    "tooltip": "Select Exclusion Blending Mode (Alt+Shift+X)"
  },
  {
    "id": "Select Hue Blending Mode",
    "description": "Select Hue Blending Mode",
    "tooltip": "Select Hue Blending Mode (Alt+Shift+U)"
  },
  {
    "id": "Select Saturation Blending Mode",
    "description": "Select Saturation Blending Mode",
    "tooltip": "Select Saturation Blending Mode (Alt+Shift+T)"
  },
  {
    "id": "Select Color Blending Mode",
    "description": "Select Color Blending Mode",
    "tooltip": "Select Color Blending Mode (Alt+Shift+C)"
  },
  {
    "id": "Select Luminosity Blending Mode",
    "description": "Select Luminosity Blending Mode",
    "tooltip": "Select Luminosity Blending Mode (Alt+Shift+Y)"
  },
  {
    "id": "composite_actions",
    "description": "Brush composite",
    "tooltip": "Brush composite"
  },
  {
    "id": "brushslider1",
    "description": "Brush option slider 1",
    "tooltip": "Brush option slider 1"
  },
  {
    "id": "brushslider2",
    "description": "Brush option slider 2",
    "tooltip": "Brush option slider 2"
  },
  {
    "id": "brushslider3",
    "description": "Brush option slider 3",
    "tooltip": "Brush option slider 3"
  },
  {
    "id": "brushslider4",
    "description": "Brush option slider 4",
    "tooltip": "Brush option slider 4"
  },
  {
    "id": "next_favorite_preset",
    "description": "Next Favourite Preset",
    "tooltip": "Next Favourite Preset (,)"
  },
  {
    "id": "previous_favorite_preset",
    "description": "Previous Favourite Preset",
    "tooltip": "Previous Favourite Preset (.)"
  },
  {
    "id": "previous_preset",
    "description": "Switch to Previous Preset",
    "tooltip": "Switch to Previous Preset (/)"
  },
  {
    "id": "show_brush_presets",
    "description": "Show Brush Presets",
    "tooltip": "Show Brush Presets (F6)"
  },
  {
    "id": "mirror_actions",
    "description": "Mirror",
    "tooltip": "Mirror"
  },
  {
    "id": "workspaces",
    "description": "Workspaces",
    "tooltip": "Workspaces"
  },
  {
    "id": "show_brush_editor",
    "description": "Show Brush Editor",
    "tooltip": "Show Brush Editor (F5)"
  },
  {
    "id": "disable_pressure",
    "description": "Use Pen Pressure",
    "tooltip": "Use Pen Pressure"
  },
  {
    "id": "paintops",
    "description": "Painter's Tools",
    "tooltip": "Painter's Tools"
  },
  {
    "id": "chooseForegroundColor",
    "description": "Open Foreground color selector",
    "tooltip": "Choose Foreground Color"
  },
  {
    "id": "chooseBackgroundColor",
    "description": "Open Background color selector",
    "tooltip": "Choose Background Color"
  },
  {
    "id": "imagesize",
    "description": "Scale Image To New Size...",
    "tooltip": "Scale Image To New Size (Ctrl+Alt+I)"
  },
  {
    "id": "canvassize",
    "description": "Resize Canvas...",
    "tooltip": "Resize Canvas (Ctrl+Alt+C)"
  },
  {
    "id": "layersize",
    "description": "Scale Layer to new Size...",
    "tooltip": "Scale Layer to new Size"
  },
  {
    "id": "scaleAllLayers",
    "description": "Scale All Layers to new Size...",
    "tooltip": "Scale All Layers to new Size"
  },
  {
    "id": "selectionscale",
    "description": "Scale...",
    "tooltip": "Scale"
  },
  {
    "id": "buginfo",
    "description": "Show Krita log for bug reports.",
    "tooltip": "Show Krita log for bug reports."
  },
  {
    "id": "sysinfo",
    "description": "Show system information for bug reports.",
    "tooltip": "Show system information for bug reports."
  },
  {
    "id": "crashlog",
    "description": "Show crash log for bug reports.",
    "tooltip": "Show crash log for bug reports."
  },
  {
    "id": "clones_array",
    "description": "Clones Array...",
    "tooltip": "Clones Array"
  },
  {
    "id": "colorrange",
    "description": "Select from Color Range...",
    "tooltip": "Select from Color Range"
  },
  {
    "id": "selectopaque",
    "description": "Select Opaque (Replace)",
    "tooltip": "Select Opaque"
  },
  {
    "id": "selectopaque_add",
    "description": "Select Opaque (Add)",
    "tooltip": "Select Opaque (Add)"
  },
  {
    "id": "selectopaque_subtract",
    "description": "Select Opaque (Subtract)",
    "tooltip": "Select Opaque (Subtract)"
  },
  {
    "id": "selectopaque_intersect",
    "description": "Select Opaque (Intersect)",
    "tooltip": "Select Opaque (Intersect)"
  },
  {
    "id": "imagecolorspaceconversion",
    "description": "Convert Image Color Space...",
    "tooltip": "Convert Image Color Space"
  },
  {
    "id": "layercolorspaceconversion",
    "description": "Convert Layer Color Space...",
    "tooltip": "Convert Layer Color Space"
  },
  {
    "id": "dbexplorer",
    "description": "Explore Resources Cache Database...",
    "tooltip": "Resources Cache Database"
  },
  {
    "id": "imagesplit",
    "description": "Image Split ",
    "tooltip": "Image Split"
  },
  {
    "id": "LayerGroupSwitcher/previous",
    "description": "Move into previous group",
    "tooltip": "Move into previous group"
  },
  {
    "id": "LayerGroupSwitcher/next",
    "description": "Move into next group",
    "tooltip": "Move into next group"
  },
  {
    "id": "layersplit",
    "description": "Split Layer...",
    "tooltip": "Split Layer"
  },
  {
    "id": "EditLayerMetaData",
    "description": "Edit metadata...",
    "tooltip": "Edit metadata"
  },
  {
    "id": "growselection",
    "description": "Grow Selection...",
    "tooltip": "Grow Selection"
  },
  {
    "id": "shrinkselection",
    "description": "Shrink Selection...",
    "tooltip": "Shrink Selection"
  },
  {
    "id": "borderselection",
    "description": "Border Selection...",
    "tooltip": "Border Selection"
  },
  {
    "id": "featherselection",
    "description": "Feather Selection...",
    "tooltip": "Feather Selection (Shift+F6)"
  },
  {
    "id": "smoothselection",
    "description": "Smooth",
    "tooltip": "Smooth"
  },
  {
    "id": "offsetimage",
    "description": "Offset Image...",
    "tooltip": "Offset Image"
  },
  {
    "id": "offsetlayer",
    "description": "Offset Layer...",
    "tooltip": "Offset Layer"
  },
  {
    "id": "QMic",
    "description": "Start G'MIC-Qt",
    "tooltip": "Start G'Mic-Qt"
  },
  {
    "id": "QMicAgain",
    "description": "Re-apply the last G'MIC filter",
    "tooltip": "Apply the last G'Mic-Qt action again"
  },
  {
    "id": "manage_bundles",
    "description": "Manage Resource Libraries...",
    "tooltip": "Manage Resource Libraries"
  },
  {
    "id": "manage_resources",
    "description": "Manage Resources...",
    "tooltip": "Manage Resources"
  },
  {
    "id": "rotateimage",
    "description": "Rotate Image...",
    "tooltip": "Rotate Image"
  },
  {
    "id": "rotateImageCW90",
    "description": "Rotate Image 90\u00b0 to the Right",
    "tooltip": "Rotate Image 90\u00b0 to the Right"
  },
  {
    "id": "rotateImage180",
    "description": "Rotate Image 180\u00b0",
    "tooltip": "Rotate Image 180\u00b0"
  },
  {
    "id": "rotateImageCCW90",
    "description": "Rotate Image 90\u00b0 to the Left",
    "tooltip": "Rotate Image 90\u00b0 to the Left"
  },
  {
    "id": "mirrorImageHorizontal",
    "description": "Mirror Image Horizontally",
    "tooltip": "Mirror Image Horizontally"
  },
  {
    "id": "mirrorImageVertical",
    "description": "Mirror Image Vertically",
    "tooltip": "Mirror Image Vertically"
  },
  {
    "id": "rotatelayer",
    "description": "Rotate Layer...",
    "tooltip": "Rotate Layer"
  },
  {
    "id": "rotateLayer180",
    "description": "Rotate Layer 180\u00b0",
    "tooltip": "Rotate Layer 180\u00b0"
  },
  {
    "id": "rotateLayerCW90",
    "description": "Rotate Layer 90\u00b0 to the Right",
    "tooltip": "Rotate Layer 90\u00b0 to the Right"
  },
  {
    "id": "rotateLayerCCW90",
    "description": "Rotate Layer 90\u00b0 to the Left",
    "tooltip": "Rotate Layer 90\u00b0 to the Left"
  },
  {
    "id": "rotateAllLayers",
    "description": "Rotate All Layers...",
    "tooltip": "Rotate All Layers"
  },
  {
    "id": "rotateAllLayersCW90",
    "description": "Rotate All Layers 90\u00b0 to the Right",
    "tooltip": "Rotate All Layers 90\u00b0 to the Right"
  },
  {
    "id": "rotateAllLayersCCW90",
    "description": "Rotate All Layers 90\u00b0 to the Left",
    "tooltip": "Rotate All Layers 90\u00b0 to the Left"
  },
  {
    "id": "rotateAllLayers180",
    "description": "Rotate All Layers 180\u00b0",
    "tooltip": "Rotate All Layers 180\u00b0"
  },
  {
    "id": "sample_screen_color",
    "description": "Sample Screen Color",
    "tooltip": "Sample a color from the screen"
  },
  {
    "id": "sample_screen_color_real_canvas",
    "description": "Sample Screen Color (Sample Real Canvas)",
    "tooltip": "Sample a color from the screen while sampling a real color from the canvas if the cursor is over it"
  },
  {
    "id": "separate",
    "description": "Separate Image...",
    "tooltip": "Separate Image"
  },
  {
    "id": "shearimage",
    "description": "Shear Image...",
    "tooltip": "Shear Image"
  },
  {
    "id": "shearlayer",
    "description": "Shear Layer...",
    "tooltip": "Shear Layer"
  },
  {
    "id": "shearAllLayers",
    "description": "Shear All Layers...",
    "tooltip": "Shear All Layers"
  },
  {
    "id": "waveletdecompose",
    "description": "Wavelet Decompose ...",
    "tooltip": "Wavelet Decompose"
  },
  {
    "id": "KisToolEncloseAndFill",
    "description": "Enclose and Fill Tool",
    "tooltip": "Enclose and Fill Tool"
  },
  {
    "id": "increase_brush_size",
    "description": "Increase Brush Size",
    "tooltip": "Increase Brush Size (])"
  },
  {
    "id": "decrease_brush_size",
    "description": "Decrease Brush Size",
    "tooltip": "Decrease Brush Size ([)"
  },
  {
    "id": "rotate_brush_tip_clockwise",
    "description": "Rotate brush tip clockwise",
    "tooltip": "Rotate brush tip clockwise"
  },
  {
    "id": "rotate_brush_tip_clockwise_precise",
    "description": "Rotate brush tip clockwise (precise)",
    "tooltip": "Rotate brush tip clockwise (precise)"
  },
  {
    "id": "rotate_brush_tip_counter_clockwise",
    "description": "Rotate brush tip counter-clockwise",
    "tooltip": "Rotate brush tip counter-clockwise"
  },
  {
    "id": "rotate_brush_tip_counter_clockwise_precise",
    "description": "Rotate brush tip counter-clockwise (precise)",
    "tooltip": "Rotate brush tip counter-clockwise (precise)"
  },
  {
    "id": "KisToolSelectPath",
    "description": "Bezier Curve Selection Tool",
    "tooltip": "Bezier Curve Selection Tool"
  },
  {
    "id": "selection_tool_mode_add",
    "description": "Selection Mode: Add",
    "tooltip": "Selection Mode: Add"
  },
  {
    "id": "selection_tool_mode_replace",
    "description": "Selection Mode: Replace",
    "tooltip": "Selection Mode: Replace"
  },
  {
    "id": "selection_tool_mode_subtract",
    "description": "Selection Mode: Subtract",
    "tooltip": "Selection Mode: Subtract"
  },
  {
    "id": "selection_tool_mode_intersect",
    "description": "Selection Mode: Intersect",
    "tooltip": "Selection Mode: Intersect"
  },
  {
    "id": "KritaShape/KisToolEllipse",
    "description": "Ellipse Tool",
    "tooltip": "Ellipse Tool (Shift+J)"
  },
  {
    "id": "KritaShape/KisToolLine",
    "description": "Line Tool",
    "tooltip": "Line Tool"
  },
  {
    "id": "KisToolSelectMagnetic",
    "description": "Magnetic Selection Tool",
    "tooltip": "Magnetic Curve Selection Tool"
  },
  {
    "id": "undo_polygon_selection",
    "description": "Undo Polygon Selection Points",
    "tooltip": "Undo Polygon Selection Points (Shift+Z)"
  },
  {
    "id": "KisToolPencil",
    "description": "Freehand Path Tool",
    "tooltip": "Freehand Path Tool"
  },
  {
    "id": "KisToolSelectContiguous",
    "description": "Contiguous Selection Tool",
    "tooltip": "Contiguous Selection Tool"
  },
  {
    "id": "InteractionTool",
    "description": "Select Shapes Tool",
    "tooltip": "Select Shapes Tool"
  },
  {
    "id": "object_order_front",
    "description": "Bring to Front",
    "tooltip": "Bring to Front (Ctrl+Shift+])"
  },
  {
    "id": "object_order_raise",
    "description": "Raise",
    "tooltip": "Raise (Ctrl+Alt+])"
  },
  {
    "id": "object_order_lower",
    "description": "Lower",
    "tooltip": "Lower (Ctrl+Alt+[)"
  },
  {
    "id": "object_order_back",
    "description": "Send to Back",
    "tooltip": "Send to Back (Ctrl+Shift+[)"
  },
  {
    "id": "object_align_horizontal_left",
    "description": "Align Left",
    "tooltip": "Align Left"
  },
  {
    "id": "object_align_horizontal_center",
    "description": "Horizontally Center",
    "tooltip": "Horizontally Center"
  },
  {
    "id": "object_align_horizontal_right",
    "description": "Align Right",
    "tooltip": "Align Right"
  },
  {
    "id": "object_align_vertical_top",
    "description": "Align Top",
    "tooltip": "Align Top"
  },
  {
    "id": "object_align_vertical_center",
    "description": "Vertically Center",
    "tooltip": "Vertically Center"
  },
  {
    "id": "object_align_vertical_bottom",
    "description": "Align Bottom",
    "tooltip": "Align Bottom"
  },
  {
    "id": "object_distribute_horizontal_left",
    "description": "Distribute Left",
    "tooltip": "Distribute left edges equidistantly"
  },
  {
    "id": "object_distribute_horizontal_center",
    "description": "Distribute Centers Horizontally",
    "tooltip": "Distribute centers equidistantly horizontally"
  },
  {
    "id": "object_distribute_horizontal_right",
    "description": "Distribute Right",
    "tooltip": "Distribute right edges equidistantly"
  },
  {
    "id": "object_distribute_horizontal_gaps",
    "description": "Distribute Horizontal Gap",
    "tooltip": "Make horizontal gaps between objects equal"
  },
  {
    "id": "object_distribute_vertical_top",
    "description": "Distribute Top",
    "tooltip": "Distribute top edges equidistantly"
  },
  {
    "id": "object_distribute_vertical_center",
    "description": "Distribute Centers Vertically",
    "tooltip": "Distribute centers equidistantly vertically"
  },
  {
    "id": "object_distribute_vertical_bottom",
    "description": "Distribute Bottom",
    "tooltip": "Distribute bottom edges equidistantly"
  },
  {
    "id": "object_distribute_vertical_gaps",
    "description": "Distribute Vertical Gap",
    "tooltip": "Make vertical gaps between objects equal"
  },
  {
    "id": "object_group",
    "description": "Group",
    "tooltip": "Group"
  },
  {
    "id": "object_ungroup",
    "description": "Ungroup",
    "tooltip": "Ungroup"
  },
  {
    "id": "object_transform_rotate_90_cw",
    "description": "Rotate 90\u00b0 CW",
    "tooltip": "Rotate object 90\u00b0 clockwise"
  },
  {
    "id": "object_transform_rotate_90_ccw",
    "description": "Rotate 90\u00b0 CCW",
    "tooltip": "Rotate object 90\u00b0 counterclockwise"
  },
  {
    "id": "object_transform_rotate_180",
    "description": "Rotate 180\u00b0",
    "tooltip": "Rotate object 180\u00b0"
  },
  {
    "id": "object_transform_mirror_horizontally",
    "description": "Mirror Horizontally",
    "tooltip": "Mirror object horizontally"
  },
  {
    "id": "object_transform_mirror_vertically",
    "description": "Mirror Vertically",
    "tooltip": "Mirror object vertically"
  },
  {
    "id": "object_transform_reset",
    "description": "Reset Transformations",
    "tooltip": "Reset object transformations"
  },
  {
    "id": "object_unite",
    "description": "Unite",
    "tooltip": "Create boolean union of multiple objects"
  },
  {
    "id": "object_intersect",
    "description": "Intersect",
    "tooltip": "Create boolean intersection of multiple objects"
  },
  {
    "id": "object_subtract",
    "description": "Subtract",
    "tooltip": "Subtract multiple objects from the first selected one"
  },
  {
    "id": "object_split",
    "description": "Split",
    "tooltip": "Split objects with multiple subpaths into multiple objects"
  },
  {
    "id": "PathTool",
    "description": "Edit Shapes Tool",
    "tooltip": "Edit Shapes Tool"
  },
  {
    "id": "pathpoint-corner",
    "description": "Corner point",
    "tooltip": "Corner point"
  },
  {
    "id": "pathpoint-smooth",
    "description": "Smooth point",
    "tooltip": "Smooth point"
  },
  {
    "id": "pathpoint-symmetric",
    "description": "Symmetric Point",
    "tooltip": "Symmetric Point"
  },
  {
    "id": "pathpoint-curve",
    "description": "Make curve point",
    "tooltip": "Make curve point"
  },
  {
    "id": "pathpoint-line",
    "description": "Make line point",
    "tooltip": "Make line point"
  },
  {
    "id": "pathsegment-line",
    "description": "Segment to Line",
    "tooltip": "Segment to Line (F)"
  },
  {
    "id": "pathsegment-curve",
    "description": "Segment to Curve",
    "tooltip": "Segment to Curve (Shift+C)"
  },
  {
    "id": "pathpoint-insert",
    "description": "Insert point",
    "tooltip": "Insert point (Ins)"
  },
  {
    "id": "pathpoint-remove",
    "description": "Remove point",
    "tooltip": "Remove point (Backspace)"
  },
  {
    "id": "path-break-point",
    "description": "Break at point",
    "tooltip": "Break at point"
  },
  {
    "id": "path-break-segment",
    "description": "Break at segment",
    "tooltip": "Break at segment"
  },
  {
    "id": "pathpoint-join",
    "description": "Join with segment",
    "tooltip": "Join with segment (J)"
  },
  {
    "id": "pathpoint-merge",
    "description": "Merge points",
    "tooltip": "Merge points"
  },
  {
    "id": "convert-to-path",
    "description": "To Path",
    "tooltip": "To Path (P)"
  },
  {
    "id": "KisToolSelectOutline",
    "description": "Freehand Selection Tool",
    "tooltip": "Freehand Selection Tool"
  },
  {
    "id": "KritaFill/KisToolGradient",
    "description": "Gradient Tool",
    "tooltip": "Draw a gradient. (G)"
  },
  {
    "id": "KisToolSelectPolygonal",
    "description": "Polygonal Selection Tool",
    "tooltip": "Polygonal Selection Tool"
  },
  {
    "id": "KritaShape/KisToolMeasure",
    "description": "Measurement Tool",
    "tooltip": "Measure the distance between two points"
  },
  {
    "id": "KisAssistantTool",
    "description": "Assistant Tool",
    "tooltip": "Assistant Tool"
  },
  {
    "id": "KisToolSelectSimilar",
    "description": "Similar Color Selection Tool",
    "tooltip": "Similar Color Selection Tool"
  },
  {
    "id": "KritaShape/KisToolRectangle",
    "description": "Rectangle Tool",
    "tooltip": "Rectangle Tool (Shift+R)"
  },
  {
    "id": "SvgTextTool",
    "description": "Text Tool",
    "tooltip": "Text Tool"
  },
  {
    "id": "KritaShape/KisToolLazyBrush",
    "description": "Colorize Mask Tool",
    "tooltip": "Colorize Mask Tool"
  },
  {
    "id": "KritaShape/KisToolBrush",
    "description": "Freehand Brush Tool",
    "tooltip": "Freehand Brush Tool (B)"
  },
  {
    "id": "set_no_brush_smoothing",
    "description": "Brush Smoothing: Disabled",
    "tooltip": "Brush Smoothing: Disabled"
  },
  {
    "id": "set_simple_brush_smoothing",
    "description": "Brush Smoothing: Basic",
    "tooltip": "Brush Smoothing: Basic"
  },
  {
    "id": "set_weighted_brush_smoothing",
    "description": "Brush Smoothing: Weighted",
    "tooltip": "Brush Smoothing: Weighted"
  },
  {
    "id": "set_stabilizer_brush_smoothing",
    "description": "Brush Smoothing: Stabilizer",
    "tooltip": "Brush Smoothing: Stabilizer"
  },
  {
    "id": "toggle_assistant",
    "description": "Toggle Snap To Assistants",
    "tooltip": "Toggle Snap to Assistants (Ctrl+Shift+L)"
  },
  {
    "id": "KritaShape/KisToolDyna",
    "description": "Dynamic Brush Tool",
    "tooltip": "Dynamic Brush Tool"
  },
  {
    "id": "KarbonCalligraphyTool",
    "description": "Calligraphy",
    "tooltip": "Calligraphy"
  },
  {
    "id": "calligraphy_increase_width",
    "description": "Calligraphy: increase width",
    "tooltip": "Calligraphy: increase width"
  },
  {
    "id": "calligraphy_decrease_width",
    "description": "Calligraphy: decrease width",
    "tooltip": "Calligraphy: decrease width"
  },
  {
    "id": "calligraphy_increase_angle",
    "description": "Calligraphy: increase angle",
    "tooltip": "Calligraphy: increase angle"
  },
  {
    "id": "calligraphy_decrease_angle",
    "description": "Calligraphy: decrease angle",
    "tooltip": "Calligraphy: decrease angle"
  },
  {
    "id": "ToolReferenceImages",
    "description": "Reference Images Tool",
    "tooltip": "Reference Images Tool"
  },
  {
    "id": "KritaSelected/KisToolColorSampler",
    "description": "Color Sampler",
    "tooltip": "Sample a color from the image or current layer (P)"
  },
  {
    "id": "KisToolPolygon",
    "description": "Polygon Tool",
    "tooltip": "Polygon Tool. Shift-mouseclick ends the polygon."
  },
  {
    "id": "KritaTransform/KisToolMove",
    "description": "Move Tool",
    "tooltip": "Move a layer (T)"
  },
  {
    "id": "movetool-move-up",
    "description": "Move up",
    "tooltip": "Move up (Up)"
  },
  {
    "id": "movetool-move-down",
    "description": "Move down",
    "tooltip": "Move down (Down)"
  },
  {
    "id": "movetool-move-left",
    "description": "Move left",
    "tooltip": "Move left (Left)"
  },
  {
    "id": "movetool-move-right",
    "description": "Move right",
    "tooltip": "Move right (Right)"
  },
  {
    "id": "movetool-move-up-more",
    "description": "Move up more",
    "tooltip": "Move up more (Shift+Up)"
  },
  {
    "id": "movetool-move-down-more",
    "description": "Move down more",
    "tooltip": "Move down more (Shift+Down)"
  },
  {
    "id": "movetool-move-left-more",
    "description": "Move left more",
    "tooltip": "Move left more (Shift+Left)"
  },
  {
    "id": "movetool-move-right-more",
    "description": "Move right more",
    "tooltip": "Move right more (Shift+Right)"
  },
  {
    "id": "movetool-show-coordinates",
    "description": "Show Coordinates",
    "tooltip": "Show absolute coordinates and offset while move action (Ctrl+Alt+Shift+C)"
  },
  {
    "id": "KritaShape/KisToolSmartPatch",
    "description": "Smart Patch Tool",
    "tooltip": "Smart Patch Tool"
  },
  {
    "id": "KisToolSelectElliptical",
    "description": "Elliptical Selection Tool",
    "tooltip": "Elliptical Selection Tool (J)"
  },
  {
    "id": "KisToolPath",
    "description": "Bezier Curve Tool",
    "tooltip": "Bezier Curve Tool. Shift-mouseclick or double-click ends the curve."
  },
  {
    "id": "KisToolSelectRectangular",
    "description": "Rectangular Selection Tool",
    "tooltip": "Rectangular Selection Tool (Ctrl+R)"
  },
  {
    "id": "ZoomTool",
    "description": "Zoom Tool",
    "tooltip": "Zoom Tool"
  },
  {
    "id": "KisToolPolyline",
    "description": "Polyline Tool",
    "tooltip": "Polyline Tool. Shift-mouseclick ends the polyline."
  },
  {
    "id": "KritaFill/KisToolFill",
    "description": "Fill Tool",
    "tooltip": "Fill a contiguous area of color with a color, or fill a selection. (F)"
  },
  {
    "id": "KritaShape/KisToolMultiBrush",
    "description": "Multibrush Tool",
    "tooltip": "Multibrush Tool (Q)"
  },
  {
    "id": "KisToolTransform",
    "description": "Transform Tool",
    "tooltip": "Transform a layer or a selection (Ctrl+T)"
  },
  {
    "id": "PanTool",
    "description": "Pan Tool",
    "tooltip": "Pan Tool"
  },
  {
    "id": "KisToolCrop",
    "description": "Crop Tool",
    "tooltip": "Crop the image to an area (C)"
  },
  {
    "id": "add_scalar_keyframes",
    "description": "Add scalar keyframes",
    "tooltip": "Adds keyframe to control scalar property"
  },
  {
    "id": "remove_scalar_keyframe",
    "description": "Remove scalar keyframe",
    "tooltip": "Removes the selected scalar keyframe"
  },
  {
    "id": "interpolation_constant",
    "description": "Hold constant value. No interpolation.",
    "tooltip": "Hold constant value. No interpolation."
  },
  {
    "id": "interpolation_linear",
    "description": "Linear interpolation.",
    "tooltip": "Linear interpolation."
  },
  {
    "id": "interpolation_bezier",
    "description": "Bezier curve interpolation.",
    "tooltip": "Bezier curve interpolation."
  },
  {
    "id": "tangents_sharp",
    "description": "Sharp interpolation tangents.",
    "tooltip": "Sharp interpolation tangents."
  },
  {
    "id": "tangents_smooth",
    "description": "Smooth interpolation tangents.",
    "tooltip": "Smooth interpolation tangents."
  },
  {
    "id": "zoom_to_fit_range",
    "description": "Zoom view to fit channel range.",
    "tooltip": "Zoom view to fit channel range."
  },
  {
    "id": "zoom_to_fit_curve",
    "description": "Zoom view to fit curve.",
    "tooltip": "Zoom view to fit curve."
  },
  {
    "id": "drop_frames",
    "description": "Drop Frames",
    "tooltip": "Drop Frames, enable to preserve playback timing."
  },
  {
    "id": "show-global-selection-mask",
    "description": "Show Global Selection Mask",
    "tooltip": "Shows global selection as a usual selection mask in <interface>Layers</interface> docker"
  },
  {
    "id": "RenameCurrentLayer",
    "description": "Rename current layer",
    "tooltip": "Rename current layer (F2)"
  },
  {
    "id": "layer_properties",
    "description": "Properties...",
    "tooltip": "Properties (F3)"
  },
  {
    "id": "remove_layer",
    "description": "Remove Layer",
    "tooltip": "Remove Layer (Shift+Del)"
  },
  {
    "id": "move_layer_up",
    "description": "Move Layer or Mask Up",
    "tooltip": "Move Layer or Mask Up (Ctrl+PgUp)"
  },
  {
    "id": "move_layer_down",
    "description": "Move Layer or Mask Down",
    "tooltip": "Move Layer or Mask Down (Ctrl+PgDown)"
  },
  {
    "id": "set-copy-from",
    "description": "Set Copy From...",
    "tooltip": "Set the source for the selected clone layer(s)."
  },
  {
    "id": "toggle_layer_soloing",
    "description": "Toggle Layer Soloing",
    "tooltip": "Toggle layer soloing, temporarily disabling visibility of all non-child layers."
  },
  {
    "id": "toggle_onion_skin",
    "description": "Toggle onion skin",
    "tooltip": "Toggle onion skin"
  },
  {
    "id": "create_snapshot",
    "description": "Create Snapshot",
    "tooltip": "Create Snapshot"
  },
  {
    "id": "switchto_snapshot",
    "description": "Switch to Selected Snapshot",
    "tooltip": "Switch to selected snapshot"
  },
  {
    "id": "remove_snapshot",
    "description": "Remove Selected Snapshot",
    "tooltip": "Remove Selected Snapshot"
  },
  {
    "id": "insert_column_left",
    "description": "Insert Column Left",
    "tooltip": "Insert column to the left of selection, moving the tail of animation to the right"
  },
  {
    "id": "insert_column_right",
    "description": "Insert Column Right",
    "tooltip": "Insert column to the right of selection, moving the tail of animation to the right"
  },
  {
    "id": "insert_multiple_columns",
    "description": "Insert Multiple Columns",
    "tooltip": "Insert several columns based on user parameters."
  },
  {
    "id": "remove_columns_and_pull",
    "description": "Remove Column and Pull",
    "tooltip": "Remove columns moving the tail of animation to the left"
  },
  {
    "id": "remove_columns",
    "description": "Remove Column",
    "tooltip": "Remove columns without moving anything around"
  },
  {
    "id": "insert_hold_column",
    "description": "Insert Hold Column",
    "tooltip": "Insert a hold column into the frame at the current position"
  },
  {
    "id": "insert_multiple_hold_columns",
    "description": "Insert Multiple Hold Columns",
    "tooltip": "Insert N hold columns into the frame at the current position"
  },
  {
    "id": "remove_hold_column",
    "description": "Remove Hold Column",
    "tooltip": "Remove a hold column from the frame at the current position"
  },
  {
    "id": "remove_multiple_hold_columns",
    "description": "Remove Multiple Hold Columns",
    "tooltip": "Remove N hold columns from the frame at the current position"
  },
  {
    "id": "mirror_columns",
    "description": "Mirror Columns",
    "tooltip": "Mirror columns' position"
  },
  {
    "id": "clear_animation_cache",
    "description": "Clear Cache",
    "tooltip": "Clear entire animation cache. Useful for debugging."
  },
  {
    "id": "copy_columns_to_clipboard",
    "description": "Copy Columns",
    "tooltip": "Copy columns to clipboard"
  },
  {
    "id": "cut_columns_to_clipboard",
    "description": "Cut Columns",
    "tooltip": "Cut columns to clipboard"
  },
  {
    "id": "paste_columns_from_clipboard",
    "description": "Paste Columns",
    "tooltip": "Paste columns from clipboard"
  },
  {
    "id": "add_blank_frame",
    "description": "Create Blank Frame",
    "tooltip": "Add blank frame"
  },
  {
    "id": "add_duplicate_frame",
    "description": "Create Duplicate Frame",
    "tooltip": "Add duplicate frame"
  },
  {
    "id": "insert_keyframe_left",
    "description": "Insert Keyframe Left",
    "tooltip": "Insert keyframes to the left of selection, moving the tail of animation to the right."
  },
  {
    "id": "insert_keyframe_right",
    "description": "Insert Keyframe Right",
    "tooltip": "Insert keyframes to the right of selection, moving the tail of animation to the right."
  },
  {
    "id": "insert_multiple_keyframes",
    "description": "Insert Multiple Keyframes",
    "tooltip": "Insert several keyframes based on user parameters."
  },
  {
    "id": "remove_frames_and_pull",
    "description": "Remove Frame and Pull",
    "tooltip": "Remove keyframes moving the tail of animation to the left"
  },
  {
    "id": "remove_frames",
    "description": "Remove Keyframe",
    "tooltip": "Remove keyframes without moving anything around"
  },
  {
    "id": "insert_hold_frame",
    "description": "Insert Hold Frame",
    "tooltip": "Insert a hold frame after every keyframe"
  },
  {
    "id": "insert_multiple_hold_frames",
    "description": "Insert Multiple Hold Frames",
    "tooltip": "Insert N hold frames after every keyframe"
  },
  {
    "id": "remove_hold_frame",
    "description": "Remove Hold Frame",
    "tooltip": "Remove a hold frame after every keyframe"
  },
  {
    "id": "remove_multiple_hold_frames",
    "description": "Remove Multiple Hold Frames",
    "tooltip": "Remove N hold frames after every keyframe"
  },
  {
    "id": "mirror_frames",
    "description": "Mirror Frames",
    "tooltip": "Mirror frames' position"
  },
  {
    "id": "copy_frames",
    "description": "Copy Keyframes",
    "tooltip": "Copy frames to clipboard"
  },
  {
    "id": "copy_frames_as_clones",
    "description": "Clone Keyframes",
    "tooltip": "Clone frames to clipboard"
  },
  {
    "id": "make_clones_unique",
    "description": "Make Unique",
    "tooltip": "Convert clones to unique keyframe instances."
  },
  {
    "id": "cut_frames",
    "description": "Cut Keyframes",
    "tooltip": "Cut frames to clipboard"
  },
  {
    "id": "paste_frames",
    "description": "Paste Keyframes",
    "tooltip": "Paste frames from clipboard"
  },
  {
    "id": "set_start_time",
    "description": "Set Start Time",
    "tooltip": "Set Start Time"
  },
  {
    "id": "set_end_time",
    "description": "Set End Time",
    "tooltip": "Set End Time"
  },
  {
    "id": "update_playback_range",
    "description": "Update Playback Range",
    "tooltip": "Update Playback Range"
  },
  {
    "id": "toggle_playback",
    "description": "Play / pause animation",
    "tooltip": "Play / pause animation"
  },
  {
    "id": "stop_playback",
    "description": "Stop animation",
    "tooltip": "Stop animation"
  },
  {
    "id": "previous_frame",
    "description": "Previous Frame",
    "tooltip": "Change time to previous frame"
  },
  {
    "id": "next_frame",
    "description": "Next Frame",
    "tooltip": "Change time to next frame"
  },
  {
    "id": "previous_keyframe",
    "description": "Previous Keyframe",
    "tooltip": "Change time to previous keyframe"
  },
  {
    "id": "next_keyframe",
    "description": "Next Keyframe",
    "tooltip": "Change time to next keyframe"
  },
  {
    "id": "previous_matching_keyframe",
    "description": "Previous Matching Keyframe",
    "tooltip": "Change time to previous keyframe of the same color"
  },
  {
    "id": "next_matching_keyframe",
    "description": "Next Matching Keyframe",
    "tooltip": "Change time to next keyframe of the same color"
  },
  {
    "id": "previous_unfiltered_keyframe",
    "description": "Previous Unfiltered Keyframe",
    "tooltip": "Change time to previous keyframe that has visible onion skin"
  },
  {
    "id": "next_unfiltered_keyframe",
    "description": "Next Unfiltered Keyframe",
    "tooltip": "Change time to next keyframe that has visible onion skin"
  },
  {
    "id": "first_frame",
    "description": "First Frame",
    "tooltip": "First Frame"
  },
  {
    "id": "last_frame",
    "description": "Last Frame",
    "tooltip": "Last Frame"
  },
  {
    "id": "auto_key",
    "description": "Auto Frame Mode",
    "tooltip": "Auto Frame Mode"
  },
  {
    "id": "show_wg_color_selector",
    "description": "Show wide-gamut color selector",
    "tooltip": "Show color selector (Shift+O)"
  },
  {
    "id": "show_wg_shade_selector",
    "description": "Show wide-gamut shade selector",
    "tooltip": "Show shade selector"
  },
  {
    "id": "show_wg_mypaint_selector",
    "description": "Show MyPaint shade selector",
    "tooltip": "Show MyPaint selector"
  },
  {
    "id": "show_wg_color_history",
    "description": "Show color history",
    "tooltip": "Show color history"
  },
  {
    "id": "wgcs_lighten_color",
    "description": "Make color lighter",
    "tooltip": "Increase value, lightness, intensity or luma of the selected color, depending on selected color model"
  },
  {
    "id": "wgcs_darken_color",
    "description": "Make color darker",
    "tooltip": "Decreases value, lightness, intensity or luma of the selected color, depending on selected color model"
  },
  {
    "id": "wgcs_increase_saturation",
    "description": "Increase color saturation",
    "tooltip": "Increase saturation of the selected color"
  },
  {
    "id": "wgcs_decrease_saturation",
    "description": "Decrease color saturation",
    "tooltip": "Decrease saturation of the selected color"
  },
  {
    "id": "wgcs_shift_hue_clockwise",
    "description": "Shift hue clockwise",
    "tooltip": "Shift hue of the selected color clockwise"
  },
  {
    "id": "wgcs_shift_hue_counterclockwise",
    "description": "Shift hue counter-clockwise",
    "tooltip": "Shift hue of the selected color counter-clockwise"
  },
  {
    "id": "file_new",
    "description": "New...",
    "tooltip": "Create new document (Ctrl+N)"
  },
  {
    "id": "file_open",
    "description": "Open...",
    "tooltip": "Open an existing document (Ctrl+O)"
  },
  {
    "id": "file_quit",
    "description": "Quit",
    "tooltip": "Quit application (Ctrl+Q)"
  },
  {
    "id": "options_configure_toolbars",
    "description": "Configure Toolbars...",
    "tooltip": "Configure Toolbars"
  },
  {
    "id": "fullscreen",
    "description": "Full Screen Mode",
    "tooltip": "Display the window in full screen (Ctrl+Shift+F)"
  },
  {
    "id": "file_open_recent",
    "description": "Open Recent",
    "tooltip": "Open a document which was recently opened"
  },
  {
    "id": "file_save",
    "description": "Save",
    "tooltip": "Save"
  },
  {
    "id": "file_save_as",
    "description": "Save As...",
    "tooltip": "Save document under a new name (Ctrl+Shift+S)"
  },
  {
    "id": "edit_undo",
    "description": "Undo Freehand Brush Stroke",
    "tooltip": "Undo last action (Ctrl+Z)"
  },
  {
    "id": "edit_redo",
    "description": "Redo ",
    "tooltip": "Redo last undone action (Ctrl+Shift+Z)"
  },
  {
    "id": "file_import_animation",
    "description": "Import animation frames...",
    "tooltip": "Import animation frames"
  },
  {
    "id": "file_import_video_animation",
    "description": "Import video animation...",
    "tooltip": "Import video animation"
  },
  {
    "id": "render_animation",
    "description": "Render Animation...",
    "tooltip": "Render Animation to GIF, Image Sequence or Video"
  },
  {
    "id": "render_animation_again",
    "description": "Render Animation Again",
    "tooltip": "Render Animation Again"
  },
  {
    "id": "file_close_all",
    "description": "Close All",
    "tooltip": "Close All (Ctrl+Shift+W)"
  },
  {
    "id": "file_import_file",
    "description": "Open existing Document as Untitled Document...",
    "tooltip": "Open existing Document as Untitled Document"
  },
  {
    "id": "file_export_file",
    "description": "Export...",
    "tooltip": "Export"
  },
  {
    "id": "file_export_advanced",
    "description": "Export Advanced...",
    "tooltip": "Advanced Export"
  },
  {
    "id": "file_documentinfo",
    "description": "Document Information",
    "tooltip": "Document Information"
  },
  {
    "id": "theme_menu",
    "description": "Themes",
    "tooltip": "Themes"
  },
  {
    "id": "view_toggledockers",
    "description": "Show Dockers",
    "tooltip": "Show Dockers"
  },
  {
    "id": "reset_configurations",
    "description": "Reset All Settings",
    "tooltip": "Reset All Settings to Default"
  },
  {
    "id": "view_detached_canvas",
    "description": "Detach Canvas",
    "tooltip": "Show the canvas on a separate window"
  },
  {
    "id": "view_toggledockertitlebars",
    "description": "Show Docker Titlebars",
    "tooltip": "Show Docker Titlebars"
  },
  {
    "id": "settings_dockers_menu",
    "description": "Dockers",
    "tooltip": "Dockers"
  },
  {
    "id": "window",
    "description": "Window",
    "tooltip": "Window"
  },
  {
    "id": "style_menu",
    "description": "Styles",
    "tooltip": "Styles"
  },
  {
    "id": "windows_cascade",
    "description": "Cascade",
    "tooltip": "Cascade"
  },
  {
    "id": "windows_tile",
    "description": "Tile",
    "tooltip": "Tile"
  },
  {
    "id": "windows_next",
    "description": "Next",
    "tooltip": "Next"
  },
  {
    "id": "windows_previous",
    "description": "Previous",
    "tooltip": "Previous"
  },
  {
    "id": "view_newwindow",
    "description": "New Window",
    "tooltip": "New Window"
  },
  {
    "id": "file_close",
    "description": "Close",
    "tooltip": "Close (Ctrl+W)"
  },
  {
    "id": "file_sessions",
    "description": "Sessions...",
    "tooltip": "Open session manager"
  },
  {
    "id": "command_bar_open",
    "description": "Search Actions",
    "tooltip": "Open the command bar to search through actions. (Ctrl+Return)"
  },
  {
    "id": "options_configure",
    "description": "Configure Krita...",
    "tooltip": "Configure Krita"
  },
  {
    "id": "expanding_spacer_0",
    "description": "Expanding Spacer",
    "tooltip": "Expanding Spacer"
  },
  {
    "id": "expanding_spacer_1",
    "description": "Expanding Spacer",
    "tooltip": "Expanding Spacer"
  },
  {
    "id": "help_contents",
    "description": "Krita Handbook",
    "tooltip": "Krita Handbook (F1)"
  },
  {
    "id": "help_report_bug",
    "description": "Report Bug...",
    "tooltip": "Report Bug"
  },
  {
    "id": "switch_application_language",
    "description": "Switch Application Language...",
    "tooltip": "Switch Application Language"
  },
  {
    "id": "help_about_app",
    "description": "About Krita",
    "tooltip": "About Krita"
  },
  {
    "id": "help_about_kde",
    "description": "About KDE",
    "tooltip": "About KDE"
  },
  {
    "id": "color_space",
    "description": "Color Space",
    "tooltip": "Color Space"
  },
  {
    "id": "document_tools",
    "description": "Document Tools",
    "tooltip": "Document Tools"
  },
  {
    "id": "export_layers",
    "description": "Export Layers",
    "tooltip": "Export Layers"
  },
  {
    "id": "filter_manager",
    "description": "Filter Manager",
    "tooltip": "Filter Manager"
  },
  {
    "id": "plugin_importer_file",
    "description": "Import Python Plugin from File...",
    "tooltip": "Import Python Plugin from File"
  },
  {
    "id": "plugin_importer_web",
    "description": "Import Python Plugin from Web...",
    "tooltip": "Import Python Plugin from Web"
  },
  {
    "id": "python_scripter",
    "description": "Scripter",
    "tooltip": "Scripter"
  },
  {
    "id": "ten_brushes",
    "description": "Ten Brushes",
    "tooltip": "Ten Brushes"
  },
  {
    "id": "activate_preset_1",
    "description": "Activate Brush Preset 1",
    "tooltip": " (Ctrl+Alt+1)"
  },
  {
    "id": "activate_preset_2",
    "description": "Activate Brush Preset 2",
    "tooltip": " (Ctrl+Alt+2)"
  },
  {
    "id": "activate_preset_3",
    "description": "Activate Brush Preset 3",
    "tooltip": " (Ctrl+Alt+3)"
  },
  {
    "id": "activate_preset_4",
    "description": "Activate Brush Preset 4",
    "tooltip": " (Ctrl+Alt+4)"
  },
  {
    "id": "activate_preset_5",
    "description": "Activate Brush Preset 5",
    "tooltip": " (Ctrl+Alt+5)"
  },
  {
    "id": "activate_preset_6",
    "description": "Activate Brush Preset 6",
    "tooltip": " (Ctrl+Alt+6)"
  },
  {
    "id": "activate_preset_7",
    "description": "Activate Brush Preset 7",
    "tooltip": " (Ctrl+Alt+7)"
  },
  {
    "id": "activate_preset_8",
    "description": "Activate Brush Preset 8",
    "tooltip": " (Ctrl+Alt+8)"
  },
  {
    "id": "activate_preset_9",
    "description": "Activate Brush Preset 9",
    "tooltip": " (Ctrl+Alt+9)"
  },
  {
    "id": "activate_preset_0",
    "description": "Activate Brush Preset 0",
    "tooltip": " (Ctrl+Alt+0)"
  },
  {
    "id": "ten_scripts",
    "description": "Ten Scripts",
    "tooltip": "Ten Scripts"
  },
  {
    "id": "execute_script_1",
    "description": "Execute Script 1",
    "tooltip": " (Ctrl+Shift+1)"
  },
  {
    "id": "execute_script_2",
    "description": "Execute Script 2",
    "tooltip": " (Ctrl+Shift+2)"
  },
  {
    "id": "execute_script_3",
    "description": "Execute Script 3",
    "tooltip": " (Ctrl+Shift+3)"
  },
  {
    "id": "execute_script_4",
    "description": "Execute Script 4",
    "tooltip": " (Ctrl+Shift+4)"
  },
  {
    "id": "execute_script_5",
    "description": "Execute Script 5",
    "tooltip": " (Ctrl+Shift+5)"
  },
  {
    "id": "execute_script_6",
    "description": "Execute Script 6",
    "tooltip": " (Ctrl+Shift+6)"
  },
  {
    "id": "execute_script_7",
    "description": "Execute Script 7",
    "tooltip": " (Ctrl+Shift+7)"
  },
  {
    "id": "execute_script_8",
    "description": "Execute Script 8",
    "tooltip": " (Ctrl+Shift+8)"
  },
  {
    "id": "execute_script_9",
    "description": "Execute Script 9",
    "tooltip": " (Ctrl+Shift+9)"
  },
  {
    "id": "execute_script_10",
    "description": "Execute Script 10",
    "tooltip": " (Ctrl+Shift+0)"
  },
  {
    "id": "recorder_record_toggle",
    "description": "Record Timelapse",
    "tooltip": "Record Timelapse"
  },
  {
    "id": "recorder_export",
    "description": "Export Timelapse...",
    "tooltip": "Export Timelapse..."
  },
  {
    "id": "mainToolBar",
    "description": "Hide File Toolbar",
    "tooltip": "Show File Toolbar"
  },
  {
    "id": "editToolBar",
    "description": "Hide Edit Toolbar",
    "tooltip": "Hide Edit Toolbar"
  },
  {
    "id": "BrushesAndStuff",
    "description": "Hide Brushes and Stuff Toolbar",
    "tooltip": "Hide Brushes and Stuff Toolbar"
  },
  {
    "id": "view_zoom",
    "description": "Zoom",
    "tooltip": "Zoom"
  },
  {
    "id": "show_color_selector",
    "description": "Show color selector",
    "tooltip": "Show color selector (Shift+I)"
  },
  {
    "id": "show_mypaint_shade_selector",
    "description": "Show MyPaint shade selector",
    "tooltip": "Show MyPaint shade selector (Shift+M)"
  },
  {
    "id": "show_minimal_shade_selector",
    "description": "Show minimal shade selector",
    "tooltip": "Show minimal shade selector (Shift+N)"
  },
  {
    "id": "show_color_history",
    "description": "Show color history",
    "tooltip": "Show color history (H)"
  },
  {
    "id": "show_common_colors",
    "description": "Show common colors",
    "tooltip": "Show common colors (U)"
  },
  {
    "id": "update_composition",
    "description": "Update Composition",
    "tooltip": "Update Composition"
  },
  {
    "id": "rename_composition",
    "description": "Rename Composition...",
    "tooltip": "Rename Composition"
  }
]




export default AvailableActions;
import React from "react";
import PrismCode from '../PrismCode'

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
//import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
# working resources
print(Krita.instance().resources('brush')) # brush image tips
print(Krita.instance().resources('gradient'))
print(Krita.instance().resources('pattern'))
print(Krita.instance().resources('workspace'))
print(Krita.instance().resources('preset')) # brush preset
print(Krita.instance().resources('palette')) # color palettes

# not working right now (could also be spelled wrong)
print(Krita.instance().resources('gamutmasks'))
print(Krita.instance().resources('svgsymbols'))
print(Krita.instance().resources('windowLayouts'))
print(Krita.instance().resources('layerstyles'))
print(Krita.instance().resources('sessions'))
print(Krita.instance().resources('tasksets'))

`;


const codeBlockTwo = `
# get all brush presets
# this returns a python dictionary
allBrushPresets = Krita.instance().resources('preset')

# get brush we want to do stuff with
# I found this by looking at the following output... 
# print( '\n'.join(list(Krita.instance().resources('preset').keys())) )
brushPreset = allBrushPresets['b) Basic-1']

# setting the brush as the current preset allows
# us to make other changes later
currentView = Krita.instance().activeWindow().activeView()
currentView.setCurrentBrushPreset(brushPreset)

# now we can change the current brush's properties
currentView.setBrushSize(34) # in pixels
currentView.setPaintingOpacity(0.3) # 0-1
currentView.setPaintingFlow(1.0) # 0-1
`;


class LessonResources extends React.Component {

  render() {
    return (
      <div>

        <h2>What are resources?</h2>

        <p>Resources are various files that come bundled with Krita. These are some types of resources that come with Krita. Things like external brush packs and "bundles" are adding additional resources.</p>

        <ul>
            <li>
                <strong>Brush presets</strong> - The configuration of how a brush will behave. Referred to with a few names: brush preset, paintoppreset, or brushes
            </li>
            <li>
                <strong>Brush image tips</strong> - Used in brushes that need a texture
            </li>
            <li>
                <strong>Patterns</strong> - Auto-tiling images that can be used as fills or assigned to a brush
            </li>
            <li>
                <strong>Gradients</strong> - Color and position data with how gradients will work
            </li>
            <li>
                <strong>Workspaces</strong> - The arrangement and visibility of dockers and the application layout
            </li>
            <li>
                <strong>Color palettes</strong> - Sets of color swatches
            </li>
            <li>
                <strong>SVG Libraries</strong> - Sets of vector objects
            </li>
            <li>
                <strong>Gamut masks</strong> - Shapes that mask out the color wheel. Used for creating color harmonies
            </li>
            <li>
                <strong>Window layouts</strong> - How the application UI is managed when on multiple monitors
            </li>
            <li>
                <strong>Layer styles</strong> - Configurations of layer styles that achieve certain effects
            </li>
            <li>
                <strong>Sessions</strong> - Configurations that store the windows and images opened. Think of it like how you would restore a session if a program crashed, and you wanted to get back to where you were with your image(s) open and all.
            </li>
            <li>
                <strong>Task sets</strong> - A set of actions that are recorded and replayed. There is a task set docker where these are created and used.
            </li>
        </ul>

        <p>How did I find all these available resource types? In the application main menu go to <strong>Settings &gt; Explore Resources Cache Database</strong>. Go to the resources tab in this window. In here there will be a resource type field where you can see the ID.</p>


        <img src="/images/lessons/resources/resource-database-viewer.png" alt="Resource database viewer" />




        <h3>Accessing resources</h3>

        <p>If you want to grab what is available for a type of resource you can do it through the Resources API. You cannot grab everything at once, but have to grab resources by type first like this. </p>

        <PrismCode code={codeBlockOne} language="python" />

        <p>Note that some of these currently are not working, but they should hopefully be working by the time Krita 5.0 comes out. This is because Krita 5.0 is re-writing how resources are managed. This is what is causing some resource types to be in a broken state at the moment.</p>


        <h3>Working with resources</h3>

        <p>Resources are all have a similar format. They are mostly file references with a bit of metadata attached to them. They really cannot do much by themselves. Other API calls require resource objects, though, so we need to understand how to grab them. Now that we do, let' work with a brush preset a bit and change some properties.</p>

        <PrismCode code={codeBlockTwo} language="python" />

        <p>This changes a few properties on the selected brush. Most of the settings for a brush preset cannot be changed through the API currently. You only have these limited options.</p>

        <h4>Limited use of resources currently</h4>
   
        <p>There really isn't much you can do with resources right now if you are really wanting full control. The  <a href="https://api.kde.org/krita/html/classView.html" target="_blank" rel="noopener noreferrer" >Krita View API Class</a> in the Krita library is where you can set active resources, but there isn't a lot else beside that. If there are additional things you would like to do, make sure to put in a feature request, or ask for help on the Krita artist forum.</p>


      </div>
    );
  }

}

export default LessonResources;



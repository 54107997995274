import React, { Component } from "react";
import IconDictionaryData from "./icon-dictionary.js";


class IconLibrary extends Component {

 constructor(props) {
    super(props);

    // adding properties to this is similar to adding class variables that you need to access elsewhere
    this.state = {
      search: null,
      isThemedCheckboxCheck: true,
      currentSelectedIconId: null,
      colorTheme: props.theme
    };
  }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
    this.setState({ currentSelectedIconId: null }); // clear any selected item if we are doing new search
  };

  checkStateChanged = (event) => {
    this.setState({isThemedCheckboxCheck: !this.state.isThemedCheckboxCheck});
  };

  
  renderImageUrl = (item) => {
  
    let fileName = item.name + '.' + item.format;
    let fileLocation = '/assets/icon-dictionary/';
  
  	// if it is a themed icon, prefix it with the light or dark word
    if (item.isThemed) {
        return  fileLocation + item.theme + '_' + fileName;; 
    }	
  
  	return fileLocation + fileName;
  };

  iconSelected = (iconId, event) => {
      this.setState({currentSelectedIconId: iconId});
  }


  changedTheme = (themeName) => {
    // component should auto-update since state has changed
    this.setState({ colorTheme: themeName });
  };


  render() {
    const selectedItemArea = () => {

      if (this.state.currentSelectedIconId == null) return;

      // grab data for selected icon
      let selectedIconData = IconDictionaryData.filter(x => x.id === this.state.currentSelectedIconId );
      selectedIconData = selectedIconData[0];

      return (
        <div>
          <hr/>
          <h3 className="selected-icon-title">Selected Icon</h3>
          <img className="icon-item-image" src={ this.renderImageUrl(selectedIconData) } alt="icon" height="48" width="48" />
          <div className="selected-icon-label">ID</div>
          <div>{ selectedIconData.name } </div>

          <div className="selected-icon-label">Format </div>
          <div>{ selectedIconData.format }</div>

           <div className="selected-icon-label">Theme support</div>
           <div> { selectedIconData.isThemed.toString()  } </div>
           { /* selectedIconData.theme */ }

      </div>
      )

    };



    const items = IconDictionaryData.filter((data) => {

      // filter by name
      if (this.state.search == null) {
        return data;
      } 
      
      if (
        data.name.toLowerCase().includes(this.state.search.toLowerCase()) 
      ) {
        return data;
      }

      return null;
    })
    .filter( (data) => {
      // filter to see if it starts with dark_ or light_
      // this means that the icon should change on theme changing

      if(this.state.isThemedCheckboxCheck) {

        // The word dark and light are actually in the file names, but they are removed in krita for icons
        // that have a dark/light pair. This property on each icon tells us if there is that dark/light icon pair
        // Also, we know we have a pair...so just show the dark version so it is easier to scan through all options
        if( data.isThemed && data.theme !== this.state.colorTheme)  { 
          return data
        }
        else {
          return null; // icon has no theme, so hide it
        }

      } 

      return data; // no theme filtering...show everything
  
    })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) // alphabetize
      .map((data, index) => {
        // output the final filtered data into HTML elements
        return (
          <button key={index} className={ `icon-item  ${  data.id === this.state.currentSelectedIconId ? "active-icon" : ""   }` } onClick={ this.iconSelected.bind(this, data.id)  } >
            <img className="icon-item-image" src={ this.renderImageUrl(data) } alt="icon" height="48" width="48" />
            <div className="icon-item-title">
              {data.name}
            </div>

          </button>
        );
      });
    return (
      <div>

      <div className="icon-library-page">

    
        <div className="icon-library-icons-container fixed-top-offset">
            <h1>Icon Library</h1>
        
            <div className="icon-container">
            {items}
            </div>
        </div>


        <div className="icon-library-search-options-container">
          <input
              id="icon-library-searchbox"
              type="text"
              placeholder="Filter icons by name"
              onChange={(e) => this.searchSpace(e)}
            /> 
            <em>{ items.length  } results</em>

            <div className="theme-checkbox-option">
              <input type="checkbox" id="is-icon-themed" checked={this.state.isThemedCheckboxCheck} onChange={ (e) => this.checkStateChanged(e)}  />
              <label htmlFor="is-icon-themed"> Dynamic Theming</label>
            </div>


            {selectedItemArea()}          
         </div>




      </div>


    


        
        
      </div>
    );
  }
}

export default IconLibrary;
